import config from "../config";
import Firebaser from "./Firebaser";
import { RecieptData } from "../model/recieptData";
const axios = require('axios');

const isDev = config.isDev;

// General constants
const DEMO_API_KEY = 'f1c85d16fc1acd369a93f0489f4615d93371632d97a9b0a197de6d4dc0da51bf';
const SUMIT_API_KEY = '01ed23a4fed14b16be6e6b3ecb2cf3e81a93c3e4e7e2998f10571022e91f2fd6';
const API_KEY = isDev ? DEMO_API_KEY : SUMIT_API_KEY;

const DEVELOPER_EMAIL = 'idan224@gmail.com';

const SUCCESS_URL_BASE_DEV = "https://us-central1-sumit-249611.cloudfunctions.net/payment_handle_1?dev=true&roomId=";
const SUCCESS_URL_BASE_PROD = "https://us-central1-sumit-249611.cloudfunctions.net/payment_handle_1?roomId=";
const SUCCESS_URL_BASE = isDev ? SUCCESS_URL_BASE_DEV : SUCCESS_URL_BASE_PROD;

const SUMIT_PAYMENT_PROXY = 'https://us-central1-sumit-249611.cloudfunctions.net/ezcount_req';

class PaymentManager {
    private static instance: PaymentManager;

    private constructor() { }

    public static getInstance = () => {
        if (!PaymentManager.instance)
            PaymentManager.instance = new PaymentManager();

        return PaymentManager.instance;
    }

    public startPayment = async (roomIds: string[], price: number) => {
        if (roomIds.length === 0)
            return;

        const clearinFormData = {
            api_key: API_KEY,
            developer_email: DEVELOPER_EMAIL,
            sum: price, // calculate including the VAT
            payments: "1-4",
            currency: 'ILS',
            successUrl: SUCCESS_URL_BASE + roomIds[0]
        };

        const response = await axios.post(SUMIT_PAYMENT_PROXY, { ...clearinFormData, url_ix: isDev ? 'dcffw' : 'cffw' });
        if (response.status !== 200 || !response.success)
            console.error("something went wrong with request", response);

        // store the token for later validation
        await Firebaser.setTransactionId(roomIds, response.data.secretTransactionId);

        window.location = response.data.url;
    }

    public getReciept = async (recieptData: RecieptData) => {
        // ensure there is a secretTransactionId, then continue creating the invoices
        let validateData = {
            api_key: API_KEY,
            developer_email: DEVELOPER_EMAIL,
        };

        const response = await axios.post(SUMIT_PAYMENT_PROXY, { ...validateData, url_ix: isDev ? 'dv' : 'v', fmt_transactionId: recieptData.transactionId });

        if (response.status !== 200 || !response.data.success) {
            alert("תקלה בולידצייה של ה-transaction id");
            return;
        }

        return await this.createDocFunction(response.data, recieptData);
    }

    private createDocFunction = async (responseBody: any, recieptData: RecieptData) => {
        const createDocData = {
            // CUSTOMER credentials
            api_key: API_KEY,
            developer_email: DEVELOPER_EMAIL,
            type: 320 /*invoice receipt*/,
            customer_name: recieptData.costumerName,
            customer_email: recieptData.costumerMail,
            item: [{
                catalog_number: 'MKT1',
                details: recieptData.description,
                amount: 1,
                price: responseBody.cgp_payment_total,
                vat_type: 'INC' //this price include the VAT
            }],
            payment: [{
                payment_type: 3 /*type CC*/,
                payment: responseBody.cgp_payment_total, //the sum field
                cc_number: responseBody.cgp_customer_cc_4_digits, //last 4 digits!!!
                cc_type_name: responseBody.cgp_customer_cc_name,
                cc_deal_type: 1 /*no payments*/,
            }],
            price_total: responseBody.cgp_payment_total,
            transaction_id: responseBody.cgp_ksys_transacion_id,
            cgp_ids: [responseBody.cgp_id],
            auto_balance: true /*in case the items sum is different then the total payments, we will add a discount, it helps solve cents calculations problem*/
        };

        const response = await axios.post(SUMIT_PAYMENT_PROXY, { ...createDocData, url_ix: isDev ? 'dcd' : 'cd' });
        if (response.status !== 200 || !response.data.success) {
            alert('שגיאה בייצור מסמך קבלה');
            return null;
        }

        return response.data.pdf_link as string;
    }
}

export default PaymentManager.getInstance();