import React, {FC, useState} from "react";
import { Dialog, DialogContent, MenuItem, MenuList, DialogTitle } from '@material-ui/core';
import "./AddDialog.css";
import { useSubscription } from "../common/useSubscription";
import { Rx } from "../../services/Rx";
import Firebaser from "../../services/Firebaser";

export const AddDialog: FC = () => {
	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}

		if(event.key === 'Escape')
			onDialogClose();
	};

	const [filter, changeFilter] = useState("");

	const onDialogClose = () => Rx.IsAddTranscriberDialogOpen.next(false);

	const addTranscriber = async (userId: string) => {
		Rx.LoadingReason.next('מוסיף מתמלל');

		await Firebaser.setUserAsTranscriber(userId);

		onDialogClose();
		Rx.LoadingReason.next('');
		Rx.IsAssignTranscriberDialogOpen.next(true);

	};

	const isOpen = useSubscription(Rx.IsAddTranscriberDialogOpen);
	const nonTranscriberUsers = useSubscription(Rx.AllUsers).filter(u => !u.transcriber);

	return (
		<>
			<Dialog open={isOpen} onClose={onDialogClose} onKeyDown={onKeyDown}>
				<DialogTitle>הוספת מתמלל</DialogTitle>
				<DialogContent>
					<input type="text" placeholder="חפש מתמלל" value={filter} onChange={(e)=>changeFilter(e.target.value)}/>
					<MenuList autoFocus>
						{nonTranscriberUsers
							.filter(user => user.phonenumber && user.phonenumber.includes(filter))
							.map(user =>
							<MenuItem
								key={user.id}
								onClick={() => addTranscriber(user.id)}
							>
								{user.phonenumber}
							</MenuItem>
						)}
					</MenuList>
				</DialogContent>
			</Dialog>
		</>
	);
}
