import React, { FC } from 'react';
import { TextField, Button, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Add } from '@material-ui/icons';
import './NewMeetingHeader.css';

interface Props {
	name: string;
	setName: (name: string) => void;
	date: Date;
	setDate: (date: Date) => void;
	uploadMeeting: (name: string, date: Date) => void;
	enableUpload: boolean;
}

export const NewMeetingHeader: FC<Props> = ({ name, setName, date, setDate, uploadMeeting, enableUpload }) => {
	return (
		<div className='newMeetingHeaderWrapper'>
			<span className="title">הקלטה חדשה</span>
			<div className='newMeetingHeader'>
				<div className='meetingHeaderInput'>
					<span>שם ההקלטה: </span>
					<TextField variant='outlined' value={name} onChange={(e) => setName(e.target.value)} 
						placeholder='חובה למלא שדה זה' 
						InputProps={{ 
							classes: {
								input: 'errorPlaceHolder'
							}	
						}} />
				</div>
				<div className='meetingHeaderInput'>
					<span>תאריך ההקלטה (אופציונלי): </span>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							disableToolbar
							inputVariant="outlined"
							variant='inline'
							format="MM/dd/yyyy"
							value={date}
							onChange={d => !!d && setDate(d)}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>

			<Paper
				elevation={3}
				className='newMeetingUploadButtonWrapper'>
				<Button
					onClick={() => uploadMeeting(name, date)}
					disabled={!enableUpload}
				>
					<Add style={{ color: "rgb(216, 43, 66)" }} /><span>הוסף לפגישות שלי</span>
				</Button>
			</Paper>
		</div>
	)
};