// This config file should not contain any sensitive information!

const project_name = "sumit-249611";
const project_id = "sumit-249611";
const project_number = "136073597195";
const messaging_sender_id = "136073597195";
const web_api_key = "AIzaSyAzaed6HrVfp_hrxcBO01zD4EOG7U9OITY";
const app_id = "1:136073597195:web:418e3ecbc0984f56";

module.exports = {
    environment: "production",
    isDev: false,
    firebase: {
        apiKey: web_api_key,
        authDomain: `${project_id}.firebaseapp.com`,
        databaseURL: `https://${project_name}.firebaseio.com`,
        projectId: project_id,
        storageBucket: `gs://${project_id}.appspot.com`,
        messagingSenderId: messaging_sender_id,
        appId: app_id
    },
    cloudFunctions: {
        url: "https://us-central1-sumit-249611.cloudfunctions.net/",
        json2srt: "json2srt"
    }
}
