import React, { useState } from "react";
import { FC } from 'react';
import { Dialog, DialogContent, MenuItem, MenuList, DialogTitle, TextField } from '@material-ui/core';
import "./AssignDialog.scss";
import { useSubscription } from "../common/useSubscription";
import { Rx } from "../../services/Rx";
import Firebaser from "../../services/Firebaser";

export const AssignDialog: FC = () => {
	const [filter, setFilter] = useState("");
	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}

		if(event.key === 'Escape')
			onClose();
	};

	const meetingsToAssign = useSubscription(Rx.MeetingsToAssign);
	const onClose = () => {
		Rx.MeetingsToAssign.next([]);
		Rx.IsAssignTranscriberDialogOpen.next(false);
	}

	const assignTranscriber = async (userId: string) => {
		const savedMeetingToAssign = meetingsToAssign;
		onClose();

		// Rx.LoadingReason.next('מעדכן את המתמלל המבוקש');
		Rx.AssigningMeetingAlert.next(true);

		const updatedMeetings = [...Rx.Meetings.value];
		savedMeetingToAssign.forEach(mId => {
			const meeting = updatedMeetings.find(m => m.id === mId);
			if(!!meeting)
				meeting.assignedTo = userId;
		});
		Rx.Meetings.next(updatedMeetings);

		const updatedBatches = [...Rx.Batches.value];
		savedMeetingToAssign.forEach(mId => {
			const batch = updatedBatches.find(b => b.recordingIds.includes(mId));
			if(!!batch) {
				batch.assignedTo = userId;
			}
		});
		Rx.Batches.next(updatedBatches);

		await Firebaser.assign(savedMeetingToAssign, userId);

		// Rx.LoadingReason.next('');
		Rx.AssigningMeetingAlert.next(false);
	};

	const addTranscriber = async () => {
		Rx.IsAddTranscriberDialogOpen.next(true)
		Rx.IsAssignTranscriberDialogOpen.next(false)
	}

	const isDialogOpen = useSubscription(Rx.IsAssignTranscriberDialogOpen);
	const userOptions = useSubscription(Rx.TranscriberIdToName);

	return (
		<>
			<Dialog open={isDialogOpen && meetingsToAssign.length > 0} onClose={onClose} onKeyDown={onKeyDown}>
				<DialogTitle>בחר מתמלל לטיפול</DialogTitle>
				<DialogContent>
					<MenuItem
						className="filterTranscribers"
					>
						<TextField
							autoFocus
							placeholder={"חפש מתמלל"}
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</MenuItem>
					<MenuList>
						<MenuItem
							key={"key1"}
							onClick={addTranscriber}
							className="addNewTranscriber"
						>
							הוסף מתמלל חדש +
						</MenuItem>
						{Object.keys(userOptions).filter(user => userOptions[user].includes(filter)).map(userId =>
							<MenuItem
								key={userId}
								onClick={() => assignTranscriber(userId)}
							>
								{userOptions[userId]}
							</MenuItem>
						)}
					</MenuList>
				</DialogContent>
			</Dialog>
		</>
	);
}
