import * as React from "react";
import { FC } from 'react';
import { Box, CircularProgress, Dialog, DialogContent, Typography } from '@material-ui/core';
import "./LoadingDialog.css";
import loading from '../../assets/loading.gif';
import { useSubscription } from "./useSubscription";
import { Rx } from "../../services/Rx";

const CircularProgressWithLabel: FC<{precentage: number}> = ({precentage}) =>
	<Box position="relative" display="inline-flex" className='loadingProgressBox'>
		<CircularProgress variant="static" value={precentage} color='secondary' />
		<Box
		top={0}
		left={0}
		bottom={0}
		right={0}
		position="absolute"
		display="flex"
		alignItems="center"
		justifyContent="center"
		>
			<Typography variant="caption" component="div" color="textSecondary">{`${Math.round(precentage)}%`}</Typography>
		</Box>
	</Box>;


export const LoadingDialog: FC = () => {
	const title = useSubscription(Rx.LoadingReason);
	const isPaymentDialogOpen = useSubscription(Rx.IsPaymentDialogOpen);

	const progress = useSubscription(Rx.UploadProgress);

	return (
		<Dialog open={!!title} style={{zIndex: isPaymentDialogOpen ? 3300 : 1300}}>
			<DialogContent className="loadingDialog">
				<span className="title">{title}</span>
				{progress >= 0 ?
					<CircularProgressWithLabel precentage={progress * 100} /> :
					<>
						<img src={loading} alt="loading..." width="120" height="120" style={{ marginTop: "1em" }} />
						<span className="dialogDescription">כמה שניות ואנחנו מתחילים...</span>
					</>
				}
			</DialogContent>
		</Dialog>
	);
}
