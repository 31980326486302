import * as React from "react";
import { FC } from 'react';
import "./MyMeetings.css";
import { Paper, MenuItem, Select, TextField } from "@material-ui/core";
import { MeetingsTree, TreeMeetingOrFolderItem } from "./MeetingsTree";
import TImeManager from "../../services/TImeManager";
import { Rx } from "../../services/Rx"

const orderOld = (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => {
	const aDate = a.minFolderDate || a.meetingMetadata?.date;
	const bDate = b.minFolderDate || b.meetingMetadata?.date;

	if(!aDate || !bDate)
		return 0;

	return TImeManager.compareDates(aDate, bDate);
};

const orderNew = (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => {
	const aDate = a.maxFolderDate || a.meetingMetadata?.date;
	const bDate = b.maxFolderDate || b.meetingMetadata?.date;

	if(!aDate || !bDate)
		return 0;

	return -TImeManager.compareDates(aDate, bDate);
};

const orderAB = (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => {
	const aName = a.folderTitle || a.meetingMetadata?.name;
	const bName = b.folderTitle || b.meetingMetadata?.name;

	if(!aName || !bName)
		return 0;

	return aName > bName ? 1 : -1;;
};

const orderDictionary = {
	"old": orderOld,
	"new": orderNew,
	"a-z": orderAB
};

export const MyMeetings: FC = () => {
	const [sorting, setSorting] = React.useState<"new" | "old" | "a-z">("new");
	const order = orderDictionary[sorting];

	return (
		<Paper className="myMeetings" elevation={10} square>
			<span className="title">הפגישות שלי</span>
			<span className='meetingsDescription' >פה אפשר למצוא את כל הפגישות שלך</span>
			<div className="meetingsHeader">
				<div className="meetingFilter">
					<span className='filterLabel'>חיפוש:</span>
					<TextField type="text" className="filterInput" variant='outlined' onChange={(event) => {
						// meeting filter quick and dirty
						Rx.FilteredMeetings.next(Rx.Meetings.value.filter(m => m.name.includes(event.target.value)))
					}}/>
				</div>
				<div className="meetingSort">
					<span className='sortLabel'>מיין פגישות לפי:</span>
					<Select
						className="sort"
						variant='outlined'
						value={sorting}
						onChange={e => setSorting(e.target.value as ("new" | "old" | "a-z"))}
					>
						<MenuItem className='sortOption' value={"new"}>מהחדש ביותר</MenuItem>
						<MenuItem className='sortOption' value={"old"}>מהישן ביותר</MenuItem>
						<MenuItem className='sortOption' value={"a-z"}>לפי סדר א"ב</MenuItem>
					</Select>
				</div>
			</div>
			<MeetingsTree compare={order} />
		</Paper>
	);
}
