import * as React from 'react';
import { FC, useState } from 'react';
import { Divider, Button, Paper } from '@material-ui/core';
// @ts-ignore
import { FilePicker } from 'react-file-picker';
import './Calculator.css';
import FileUploadManager from '../../services/FileUploadManager';
import { Rx } from '../../services/Rx';
import { useSubscription } from '../common/useSubscription';
import cloud from '../../assets/cloud-play-icon.png';
import calculator from '../../assets/calculator-icon.png';
import computer from '../../assets/computer-chat-icon.png';
import PaymentManager from '../../services/PaymentManager';
import PriceProvider from '../../services/PriceProvider';
import { PaymentDialog } from './PaymentDialog';
import Firebaser from '../../services/Firebaser';

export const Calculator: FC = () => {
	const userId = useSubscription(Rx.ConnectedUserId);
	const userName = useSubscription(Rx.ConnectedUserName);
	const userMail = useSubscription(Rx.ConnectedUserMail);
	const hasAuthed = !!userId && !!userName && !!userMail;

	const recordings = useSubscription(Rx.Recordings);

	const [fileToUpload, setFileToUpload] = useState<any>(null);
	React.useEffect(() => {
		if (hasAuthed && !!fileToUpload)
			FileUploadManager.handleFileChosen(fileToUpload, () => Rx.IsPaymentDialogOpen.next(true), false);
	}, [hasAuthed, fileToUpload]);

	const handleFileChosen = (file: any) => {
		setFileToUpload(file);
		Rx.HasCostumerUploaded.next(true);
	}

	const uploadMeetingWithPay = async () => {
		Rx.LoadingReason.next("שומרים את המידע שלך");

		const roomIds = recordings.map(rec => rec.meetingId);
		const price = PriceProvider.getPrice(recordings.map(rec => rec.length));

		const groupGeneratedID = Firebaser.generateID();

		for(const rec of recordings) {
			await Firebaser.uploadUnsupervised({
				meetingId: rec.meetingId,
				groupId: groupGeneratedID,
				meetingDate: rec.date,
				meetingName: rec.name,
				participants: [],
				meetingLengthInSec: rec.length,
				ranges: {}
			});
		}

		Rx.LoadingReason.next("מעבירים אותך לתשלום");
		setTimeout(() => {
			Rx.LoadingReason.next("");
			Rx.IsDoneUploading.next(true);
		}, 1000)
		// await PaymentManager.startPayment(roomIds, price);
	};

	const mediaTypes = useSubscription(Rx.MediaTypes);

	return (
		<Paper className="calculatorWrapper" elevation={10} square >
			<span className='title'>איך זה עובד?</span>
			<div className='steps'>
				<div>
					<img src={cloud} alt="" />
					<span>שולחים כאן את הקובץ אותו תרצו לתמלל</span>
				</div>
				<div>
					<img src={calculator} alt="" />
					<span>מקבלים הצעת מחיר מיידית מהמחשבון החכם שלנו</span>
				</div>
				<div>
					<img src={computer} alt="" />
					<span>מקבלים את התוצר המתומלל למייל עד 24 שעות</span>
				</div>
				<span className='subtitlesService'>מספקים גם שירותי כתוביות!</span>
			</div>
			<Divider />
			<FilePicker
				extensions={mediaTypes}
				maxSize={2000}
				onChange={handleFileChosen}
				onError={(err: string) => alert("נה העלו פורמט אחר")} >
				<Button variant='outlined' className='uploadButton'>לחצו כאן לשליחת קובץ לתמלול</Button>
			</FilePicker>
			<PaymentDialog onPay={uploadMeetingWithPay}/>
		</Paper>
	);
}
