class IndexedListColorManager {
    private static instance: IndexedListColorManager;

    private constructor() { }

    public static getInstance = () => {
        if (!IndexedListColorManager.instance)
            IndexedListColorManager.instance = new IndexedListColorManager();

        return IndexedListColorManager.instance;
    }

    private colors: string[] = ["red", "green", "blue", "purple", "orange", "brown", "cyan", "gray", "pink"];

    public getColorByIndex = (i: number) => {
        return this.colors[i % this.colors.length];
    }
}

export default IndexedListColorManager.getInstance();