import React, { useEffect } from 'react';
import './App.css';
import { Home } from './components/HomePage/Home';
import Firebaser from './services/Firebaser';
import firebase from 'firebase';
import WordsManager from './services/WordsManager';
import { Rx } from './services/Rx';
import SoundManager from './services/SoundManager';

const App = () => {
  useEffect(() => {
    Firebaser.verifier = new firebase.auth.RecaptchaVerifier('sign-in-button', { 'size': 'invisible' });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (!e.ctrlKey)
        return;

      switch (e.keyCode) {
        case 83:
          !e.defaultPrevented && WordsManager.saveChangedMeeting();
          e.preventDefault();
          break;
        case 77:
          Rx.IsPlaying.value ? SoundManager.pause() : SoundManager.play();
          e.preventDefault();
          break;
        case 190:
          SoundManager.playRelative(5);
          e.preventDefault();
          break;
        case 188:
          SoundManager.playRelative(-5);
          e.preventDefault();
          break;
      }
    }, false);
  }, []);

  return (
    <>
      <div id="sign-in-button" style={{ visibility: 'hidden' }}></div>
      <Home />
    </>
  );
}

export default App;
