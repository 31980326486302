import React, { useState, FC, useEffect } from 'react';
import { InputBase } from '@material-ui/core';
import './SecondsEditor.css';

interface Props {
	label: string;
	totalSeconds: number;
	setTotalSeconds: (secs: number) => void;
}

export const SecondsEditor: FC<Props> = ({ label, totalSeconds, setTotalSeconds }) => {
	const [secs, setSecs] = useState('');
	const [mins, setMins] = useState('');

	const getStringFromProps = () => {
		setSecs(totalSeconds < 0 ? '' : `${totalSeconds % 60}`);
		setMins(totalSeconds < 0 ? '' : `${Math.floor(totalSeconds / 60)}`);
	};
	useEffect(getStringFromProps, [totalSeconds]);

	const updateTotalSeconds = () => {
		if (!!secs && !!mins) {
			setTotalSeconds(parseInt(secs) + parseInt(mins) * 60);
			getStringFromProps();
		}
	};

	const changeSecs = () => {
		if (secs.length === 0 || secs.length > 2 || !/^\d+$/.test(secs)) {
			setSecs(totalSeconds < 0 ? '' : `${totalSeconds % 60}`);
			return;
		}

		const numberValue = parseInt(secs);
		if (numberValue > 60) {
			setSecs(totalSeconds < 0 ? '' : `${totalSeconds % 60}`);
			return;
		}

		updateTotalSeconds();
	}

	const changeMins = () => {
		if (mins.length === 0 || mins.length > 3 || !/^\d+$/.test(mins)) {
			setMins(totalSeconds < 0 ? '' : `${Math.floor(totalSeconds / 60)}`);
			return;
		}

		updateTotalSeconds();
	}

	return (
		<div className="timePickerWithLabel">
			<span className="timeLabel">{label}</span>
			<div className="timePickerWrapper">
				<InputBase className="timePicker"
						   value={secs}
						   inputProps={{
						   	maxLength:3
						   }}
						   onChange={e => setSecs(e.target.value)}
						   onBlur={changeSecs} />
				<b>:</b>
				<InputBase className="timePicker"
						   value={mins}
						   inputProps={{
						   	maxLength:3
						   }}
						   onChange={e => setMins(e.target.value)}
						   onBlur={changeMins} />
			</div>
		</div>
	)
}
