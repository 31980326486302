class UrlReader {
    private static instance: UrlReader;

    private constructor() { }

    public static getInstance = () => {
        if (!UrlReader.instance)
            UrlReader.instance = new UrlReader();

        return UrlReader.instance;
    }

    public syncMeetingIdFromUrl = () => {
        const path: string[] = window.location.pathname.split("/");
        return path[path.length - 1];
    }

    public isEditorHome = () => {
        return window.location.pathname === "/";
    }

    public isUploadPage = () => {
        return window.location.pathname === "/new";
    }

    public isEditPage = () => {
        return window.location.pathname.startsWith("/meeting");
    }
}

export default UrlReader.getInstance();
