import React, { FC, useEffect } from 'react';
import { ThinRange } from './SpeakerItem';
import './RangePicker.css';
import { SecondsEditor } from './SecondsEditor';
import { CancelOutlined } from '@material-ui/icons';
import { Divider, IconButton } from '@material-ui/core';

interface Props {
	range: ThinRange;
	updateRange: (newRange?: ThinRange) => void;
	color: string;
}

export const RangePicker: FC<Props> = ({ range, updateRange, color }) => {
	useEffect(() => {
		document.addEventListener("keydown", (e) => {
			if (e.key === 'Tab') {
				e.preventDefault();
				e.stopPropagation();
			}
		}, false);
	}, []);

	const updateStart = (newStart: number) => {
		updateRange({ start: newStart, end: range.end });
	};

	const updateEnd = (newEnd: number) => {
		updateRange({ start: range.start, end: newEnd });
	};

	return (
		<div className="rangePickerWrapper">
			<IconButton
				style={{
					position: 'absolute',
					right: '1em',
					top: '-0.2em',
					height: 'fit-content',
					padding: '0'
				}}
				onClick={() => updateRange()}
			>
				<CancelOutlined style={{
					width: '17px',
					color: color,
					backgroundColor: 'white'
				}} />
			</IconButton>
			<div className="rangePicker">
				<SecondsEditor label={"מ-"} totalSeconds={Math.floor(range.start)} setTotalSeconds={updateStart} />
				<Divider orientation="vertical" flexItem />
				<SecondsEditor label={"עד"} totalSeconds={Math.floor(range.end)} setTotalSeconds={updateEnd} />
			</div>
		</div >
	);
}