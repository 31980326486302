import React, { FC, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Rx } from '../../services/Rx';
import { Delete } from '@material-ui/icons';
import Firebaser from '../../services/Firebaser';
import { RecordingBatch } from '../../model/recordingBatch';
import { MeetingPreview } from '../../model/meetingPreview';

interface Props {
    meeting?: MeetingPreview;
    batch?: RecordingBatch;
    getMeetingsIds?: () => string[];
}

export const DeleteButton: FC<Props> = ({meeting, batch, getMeetingsIds}) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const func = async () => setIsAdmin(await Firebaser.isAdmin(Rx.ConnectedUserId.value))
        func();
    }, []);

    if(!isAdmin)
        return <></>;

    if(!!getMeetingsIds)
        return <IconButton onClick={() => Rx.MeetingsToDelete.next(getMeetingsIds())}><Delete style={{color:'gray'}}/></IconButton>

    let meetingIds: string[];

    if(!!batch)
        meetingIds = batch.recordingIds;
    else if(!meeting)
        return <></>;
    else
        meetingIds = [meeting.id];

    return <IconButton className="deleteButton" onClick={() => Rx.MeetingsToDelete.next(meetingIds)}><Delete style={{color: 'gray'}} /></IconButton>;
}
