import * as React from 'react';
import { FC } from 'react';
import { Dialog, Button, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import './RecieptPage.css';
import { useHistory } from 'react-router-dom';
import UrlReader from '../../services/UrlReader';

export const RecieptError: FC = () => {
	const history = useHistory();
	const meetingId = UrlReader.syncMeetingIdFromUrl();

	return (
		<Dialog open={true} >
			<div className='recieptDialog'>
				<DialogTitle>
					משהו השתבש בעיבוד העסקה :(
				</DialogTitle>
				<DialogContent>
					פנו אלינו בוואטסאפ, או למייל info@sumit-ai.com
					<br />
					יחד עם מספר התקלה ({meetingId}) ונעזור לטפל בה
				</DialogContent>
				<DialogActions>
					<Button onClick={() => history.push('/')}>חזור לעמוד הבית</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}