import _ from "lodash";
import {Word} from "../model/word";

class EditorService {
    private static instance: EditorService;

    public static getInstance = () => {
        if (!EditorService.instance)
            EditorService.instance = new EditorService();

        return EditorService.instance;
    }

    generateWordId = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };

    getMeetingWordsFromString = (
        meetingWords: Word[],
        newInputString: string,
        oldInputString: string,
        firstWordIndex: number,
        speaker: string,
        rangesCount: number
    ) => {
        const fullWordsArray = meetingWords.filter(i => i.text !== "\n"); // Removing linebreaks
        const newWords = newInputString
            .trim()
            .split(' ')
            .filter(i => i); // Removing whitespace
        const oldWords = oldInputString
            .trim()
            .split(' ')
            .filter(i => i); // Removing whitespace

        let lastNewWordIndex = newWords.length - 1;
        let lastOldWordIndex = oldWords.length - 1;

        let startI = 0;
        while (newWords[startI] === oldWords[startI]
        && !!newWords[startI]) {
            startI++;
        }
        let endI = 0;
        while (newWords[lastNewWordIndex - endI] === oldWords[lastOldWordIndex - endI] // Last new word == last old word
        && !!newWords[lastNewWordIndex - endI] // Last new word exists
        && lastOldWordIndex - endI > startI  // Current iteration last word is the last new word
        && lastNewWordIndex - endI > startI) {
            endI++;
        }

        let indexToCopy = startI + firstWordIndex;
        if (startI > lastOldWordIndex && indexToCopy > 0)
            indexToCopy--;

        const wordToCopy = meetingWords[indexToCopy];

        const changedWordsObjects: Word[] = newWords.slice(startI, newWords.length - endI).map(str => ({
            ...wordToCopy,
            id: this.generateWordId(),
            speaker: speaker,
            text: str,
            word: str
        }));

        const updatedMeetingWords: Word[] = fullWordsArray.slice(0, firstWordIndex + startI);
        updatedMeetingWords.push(...changedWordsObjects);
        let lastWord = fullWordsArray;
        lastWord = lastWord.slice(firstWordIndex + oldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)); // When single speaker (rangesCount === 0) removing also last word (-1)
        updatedMeetingWords.push(...lastWord);

        return updatedMeetingWords
    }

    getSelectedWordsIndex = (plainWords: string, selectionStart: number, selectionEnd: number) => {
        const editedWords = plainWords.split(' ');
        const lengths = editedWords.map(word => word.length);

        let start = selectionStart;

        let startWordIndex = -1;
        while (start > 0) {
            startWordIndex++;
            start = start - lengths[startWordIndex] - 1;
        }
        if (start === 0)
            startWordIndex++;

        let end = selectionEnd;

        let endWordIndex = -1;
        while (end > 0) {
            endWordIndex++;
            end = end - lengths[endWordIndex] - 1;
        }
        if (end === 0)
            endWordIndex++;

        return { startWordIndex, endWordIndex }
    };
}

export default EditorService.getInstance();
