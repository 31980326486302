import { Rx } from "./Rx";

class SoundManager {
    private static instance: SoundManager;

    public static getInstance = () => {
        if (!SoundManager.instance)
            SoundManager.instance = new SoundManager();

        return SoundManager.instance;
    }

    private audio: HTMLAudioElement;
    private media: HTMLAudioElement|HTMLVideoElement;
    private mediaOffset: number = 0;

    public setOffset = (offset: number) => {
        if (!this.media)
            return;

        this.media.currentTime = offset + this.mediaOffset;
    };

    public playOffset = (offset?: number) => {
        if (!this.media)
            return;

        Rx.IsPlaying.next(true);
        this.media.pause();
        this.media.currentTime = this.mediaOffset + (offset || 0);
        this.media.play();
    };

    public play = () => {
        if (this.media) this.media.play();
        Rx.IsPlaying.next(true);
    };

    public playRelative = (relativeOffset: number) => {
        if (!this.media)
            return;

        let offset = this.media.currentTime + relativeOffset;
        offset = Math.max(offset, 0);

        this.playOffset(offset);
    };

    public pause = () => {
        if (this.media) this.media.pause();

        Rx.IsPlaying.next(false);
    };

    public initializeAudio = (src: string, generalOffset?: number) => {
        console.log("initialized")
        this.mediaOffset = generalOffset || 0;

        const shouldKeepPlaying = !!this.audio;

        this.pause();

        console.log(`loading source file: ${src}`);
        this.audio = new Audio(src);

        this.audio.currentTime = Rx.CurrentTime.value + this.mediaOffset;
        this.audio.ontimeupdate = () => {
            Rx.CurrentTime.next(this.audio.currentTime - this.mediaOffset);
        }

        if (shouldKeepPlaying)
            this.play();
    };

    public initializeMedia = (src: string, generalOffset?: number, videoContainerId: any = null) => {
        console.log("initialized")
        this.mediaOffset = generalOffset || 0;

        const shouldKeepPlaying = !!this.media;

        this.pause();

        console.log(`loading source file: ${src}`);
        this.media = videoContainerId ? SoundManager.Video(src, videoContainerId) : new Audio(src);

        this.media.currentTime = Rx.CurrentTime.value + this.mediaOffset;
        this.media.ontimeupdate = () => {
            Rx.CurrentTime.next(this.media.currentTime - this.mediaOffset);
        }

        if (shouldKeepPlaying)
            this.play();
    };

    public changeSpeed = (speed: number) => {
        if (!this.media)
            return;

        this.media.playbackRate = speed;
    };

    private static Video(src: string, videoContainerId: string) {
        let v = document.createElement("video");
        if (src != "") {
            v.src = src;
        }

        const videoContainer = document.getElementById(videoContainerId);
        videoContainer && videoContainer.appendChild(v);

        return v;
    }
}

export default SoundManager.getInstance();
