import * as React from "react";
import { FC, ReactNode } from 'react';
import { Dialog, TextField, Button, DialogContent } from '@material-ui/core';
import './SingleInputDialog.css'
import logo from '../../assets/logo.jpeg';


interface Props {
	open: boolean;
	onInput: (input: string) => void;
	title?: string;
	placeholder: string;
	buttonText: string;
	notConnectionDialog?: boolean;
	onClose?: () => void;
	ltrInput?: boolean;
	isMail?: boolean;
	emailSent?: boolean;
	changeAuthMethod?: React.Dispatch<boolean>;
	error?: string;
	extraContent?: ReactNode;
}

export const SingleInputDialog: FC<Props> = ({
												 open,
												 onInput,
												 title,
												 placeholder,
												 buttonText,
												 notConnectionDialog,
												 onClose,
												 ltrInput,
												 isMail,
												 emailSent,
												 changeAuthMethod,
												 error,
												 extraContent
}) => {
	const [input, setInput] = React.useState("");
	const [disabled, setDisabled] = React.useState(false);

	React.useEffect(() => {
		setInput('');
		setDisabled(false);
	}, [open, error]);

	const sendInput = () => {
		onInput(input);
		setDisabled(true);
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			sendInput();
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent className="inputDialog">
				{!notConnectionDialog && <img src={logo} alt="Sumit" style={{ height: "2em", marginBottom: "1em" }} />}
				{(!notConnectionDialog || !!title) && <span className="title">{emailSent ? "מייל נשלח בהצלחה" : title || "התחברות"}</span>}
				{!notConnectionDialog && !error && !title && !emailSent && <span className="dialogDescription">כדי להתחבר למערכת נצטרך לזהות אותך</span>}
				{!!error && <span style={{ margin: '1em', textAlign: 'center', color: "red" }}>{error}</span>}

				{emailSent ? (
					<div>לינק להתחברות נשלח בהצלחה, בדוק בבקשה את תיבת הדואר שלך</div>
				) : (
					<TextField
					disabled={disabled}
					value={input}
					inputProps={{min: 0, style: {textAlign: 'center'}}}
					style={{direction: !input || !ltrInput ? 'rtl' : 'ltr'}}
					placeholder={placeholder}
					onChange={(e) => setInput(e.target.value)}
					variant="outlined"
					autoFocus
					onKeyDown={onKeyDown}/>)
				}
				{!!extraContent && extraContent}
				{!emailSent && (<Button disabled={disabled}
					onClick={() => sendInput()}
					variant={"outlined"}
					id="dialogButton">{buttonText}</Button>) }
				{!emailSent && changeAuthMethod ? <a href={"#"} onClick={() => changeAuthMethod(!isMail)} className="loginProblems">בעיות התחברות? הזדהה באמצעות {isMail ? "טלפון" : "מייל"}</a> : ""}
			</DialogContent>
		</Dialog>
	);
}
