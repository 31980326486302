import * as React from 'react';
import { FC } from 'react';
import UrlReader from '../../services/UrlReader';
import { Dialog, Button, DialogActions, DialogTitle } from '@material-ui/core';
import Firebaser from '../../services/Firebaser';
import PaymentManager from '../../services/PaymentManager';
import { RecieptData } from '../../model/recieptData';
import { Rx } from '../../services/Rx';
import './RecieptPage.css';
import { useHistory } from 'react-router-dom';

export const RecieptPage: FC = () => {
	const history = useHistory();

	const getReciept = async () => {
		Rx.LoadingReason.next('מדפיסים קבלה עבורך');
		const meetingId = UrlReader.syncMeetingIdFromUrl();

		const recieptData = await Firebaser.getRecieptData(meetingId);
		if (!recieptData) {
			Rx.LoadingReason.next('');
			return;
		}

		const pdfUrl = await PaymentManager.getReciept(recieptData as RecieptData);
		if (!pdfUrl) {
			Rx.LoadingReason.next('');
			return;
		}

		window.open(pdfUrl);

		Rx.LoadingReason.next('');
		history.push('/');
	};

	return (
		<Dialog open={true} >
			<div className='recieptDialog'>
				<DialogTitle>
					העסקה התקבלה בהצלחה!
			</DialogTitle>
				<DialogActions>
					<Button onClick={getReciept}>הורד קבלה, שלח למייל והמשך</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
}