import * as React from "react";
import { version } from "../../../package.json";
import { FC, useEffect } from 'react';
import { NewMeetingPage } from "../RangePickPage/NewMeetingPage";
import { Autentication } from "./Autentication";
import "./Home.css";
import { TopBar } from "../common/TopBar";
import { Route, Switch, Redirect } from "react-router-dom";
import { MeetingPage } from "../EditorPage/MeetingPage";
import { LoadingDialog } from "../common/LoadingDialog";
import { Information } from "../LandingPage/Information";
import { Calculator } from "../LandingPage/Calculator";
import { useSubscription } from "../common/useSubscription";
import { Rx } from "../../services/Rx";
import { MeetingsHome } from "./MeetingsHome";
import { RecieptPage } from "../recieptPage/RecieptPage";
import { RecieptError } from "../recieptPage/RecieptError";
import Firebaser from "../../services/Firebaser";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

export const Home: FC = () => {
	const hasCostumerUploaded = useSubscription(Rx.HasCostumerUploaded);
	const landingView = useSubscription(Rx.LandingView);
	const assigningMeetingAlert = useSubscription(Rx.AssigningMeetingAlert);
	const savedAlert = useSubscription(Rx.SavedAlert);
	const savingAlert = useSubscription(Rx.SavingAlert);
	const savingFailed = useSubscription(Rx.SavingFailed);

	const doneAssigningMeetingAlert = () => Rx.AssigningMeetingAlert.next(false);
	const doneAlertingSave = () => Rx.SavedAlert.next(false);
	const doneAlertingSaving = () => Rx.SavingAlert.next(false);
	const doneAlertingSavingError = () => Rx.SavingFailed.next(false);

	useEffect(() => {
		Firebaser.getMediaTypes();
	}, []);

	return (
		<>
			<LoadingDialog />
			<TopBar />
			<Switch>
				<Route exact path="/reciept/:id">
					<RecieptPage />
				</Route>
				<Route exact path="/clearingError/:id">
					<RecieptError />
				</Route>
				<Route exact path="/meeting/:id">
					<MeetingPage />
				</Route>
				<Route exact path="/new">
					<NewMeetingPage />
				</Route>
				<Route exact path="/">
					<MeetingsHome />
					<Autentication />
				</Route>
				<Route exact path="/">
					<div className="homeContainer">
						{landingView === "information" && <Information />}
						{landingView === "calculator" && <Calculator />}
					</div>
					<Autentication shouldStart={hasCostumerUploaded} />
				</Route>
				<Redirect to="/" />
			</Switch>
			<Snackbar
				open={savedAlert}
				autoHideDuration={4000}
				onClose={doneAlertingSave}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={doneAlertingSave} severity="success">
					סיכום הפגישה נשמר
				</Alert>
			</Snackbar>
			<Snackbar
				open={savingAlert}
				onClose={doneAlertingSaving}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={doneAlertingSaving} severity="info">
					שומר פגישה
				</Alert>
			</Snackbar>
			<Snackbar
				open={savingFailed}
				onClose={doneAlertingSavingError}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={doneAlertingSavingError} severity="error">
					שמירת פגישה נכשלה - צור קשר עם המנהל שלך
				</Alert>
			</Snackbar>
			<Snackbar
				open={assigningMeetingAlert}
				onClose={doneAssigningMeetingAlert}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			>
				<Alert onClose={doneAssigningMeetingAlert} severity="info">
					מעביר לטיפול
				</Alert>
			</Snackbar>
			<div className="version">v{version}</div>
		</>
	);
}
