import React, { FC, useState, useEffect } from 'react';
import { ColoredPlayer } from '../EditorPage/ColoredPlayer';
import { NewMeetingHeader } from './NewMeetingHeader';
import { SpeakersList } from './SpeakersList';
import { Range } from '../EditorPage/ColoredSeekBar';
import IndexedListColorManager from '../../services/IndexedListColorManager';
import { useSubscription } from '../common/useSubscription';
import { Rx } from '../../services/Rx';
import { Paper, Button } from '@material-ui/core';
import './NewMeetingPage.css';
import Firebaser from '../../services/Firebaser';
import { useHistory } from 'react-router-dom';
import SoundManager from '../../services/SoundManager';
import { ThinRange } from './SpeakerItem';
import { Recording } from '../../model/recording';
import { Delete } from '@material-ui/icons';
// import whatsapp from '../../assets/whatsapp-icon.png';
import { AudioSource } from '../../model/meeting';
import { RangesInfo } from '../../model/rangesInfo';

export const NewMeetingPage: FC = () => {
	const recordings: Recording[] = useSubscription(Rx.Recordings);
	const currentRecordingInd: number = useSubscription(Rx.CurrentRecordingInd);

	const history = useHistory();
	useEffect(() => {
		if (recordings.length === 0)
			history.push('/');
	}, [recordings, history]);

	useEffect(() => () => Rx.Recordings.next([]), []);

	const currentRecording = recordings[currentRecordingInd];
	useEffect(() => {
		if (!!currentRecording){
			Rx.CurrentTime.next(0);
			SoundManager.initializeMedia(currentRecording.audio);
		}
	}, [currentRecording]);

	const doesNewRangeIntersect = (newRange: ThinRange | Range, rangeIndex?: number) => {
		const rangeStart = newRange.start;
		const rangeEnd = newRange.end || newRange.start;

		const r = recordings[currentRecordingInd].ranges.find((_, i) => !!_.end && _.end > rangeStart && (i !== rangeIndex));
		return !!r && r.start < rangeEnd;
	}

	const addRange = (speakerIndex: number, newRange: ThinRange) => {
		const color = IndexedListColorManager.getColorByIndex(speakerIndex);

		if (doesNewRangeIntersect(newRange)) {
			alert("אין להגדיר טווחי זמנים חופפים");
			Rx.Recordings.next([...recordings]);
			return;
		}

		recordings[currentRecordingInd].ranges = [...recordings[currentRecordingInd].ranges, {
			start: newRange.start,
			end: newRange.end,
			speaker: `${speakerIndex}`,
			color: color
		}].sort((a, b) => - b.start + a.start);
		Rx.Recordings.next([...recordings]);
	};

	const updateRange = (rangeIndex: number, newRange?: Range) => {
		if (!newRange) {
			const newRanges = recordings[currentRecordingInd].ranges.filter((_, i) => i !== rangeIndex);
			recordings[currentRecordingInd].ranges = newRanges;
			Rx.Recordings.next([...recordings]);
			return;
		}

		if (doesNewRangeIntersect(newRange, rangeIndex)) {
			alert("אין להגדיר טווחי זמנים חופפים");
			Rx.Recordings.next([...recordings]);
			return;
		}

		const newRanges = [...recordings[currentRecordingInd].ranges];
		newRanges[rangeIndex] = newRange;
		recordings[currentRecordingInd].ranges = newRanges;
		Rx.Recordings.next([...recordings]);
	}

	const changeSpeakerName = (index: number, newName: string) => {
		const newSpeakers = [...recordings[currentRecordingInd].speakers];
		newSpeakers[index] = newName;
		recordings[currentRecordingInd].speakers = newSpeakers;
		Rx.Recordings.next([...recordings]);
	};

	const addSpeaker = () => {
		recordings[currentRecordingInd].speakers.push("");
		Rx.Recordings.next([...recordings]);
	};

	const deleteSpeaker = (index: number) => {
		const newRanges = recordings[currentRecordingInd].ranges.filter(r => parseInt(r.speaker) !== index).map(
			r => {
				const prevInd = parseInt(r.speaker);
				const newSpeakerIndex = prevInd > index ? prevInd - 1 : prevInd;
				return { ...r, speaker: `${newSpeakerIndex}`, color: IndexedListColorManager.getColorByIndex(newSpeakerIndex) };
			}
		);
		recordings[currentRecordingInd].ranges = newRanges;

		const newSpeakers = recordings[currentRecordingInd].speakers.filter((_, i) => i !== index);
		recordings[currentRecordingInd].speakers = newSpeakers;

		Rx.Recordings.next([...recordings]);
	};

	const uploadMeetingWithoutPay = async () => {
		Rx.LoadingReason.next("מוסיפים את הפגישה ליומן");

		await uploadMeeting();

		Rx.Recordings.next([]);
		Rx.LoadingReason.next("");
		history.push('/');
	};

	const uploadMeeting = async () => {
		const groupGeneratedID = Firebaser.generateID();
		for (const rec of recordings) {

			const rangesUploadFormat: { [speakerId: string]: number[][] } = {};
			rec.ranges.forEach((range) => {
				const speaker = rec.speakers[parseInt(range.speaker)];
				if (!range.end)
					return;
				if (!rangesUploadFormat[speaker])
					rangesUploadFormat[speaker] = [];
				rangesUploadFormat[speaker].push([range.start, range.end]);
			});

			const ranfesInfo: RangesInfo = {
				meetingId: rec.meetingId,
				groupId: groupGeneratedID,
				meetingDate: rec.date,
				meetingName: rec.name,
				participants: rec.speakers.filter((s, i, array) => array.indexOf(s) === i),
				meetingLengthInSec: rec.length,
				ranges: rangesUploadFormat
			};

			await Firebaser.uploadSupervised(ranfesInfo);
		}

		Rx.CurrentTime.next(0);
		SoundManager.pause();
	};

	const deleteRecording = async () => {
		Rx.LoadingReason.next('מוחקים את ההקלטה');

		const meetingIdToRemove = recordings[currentRecordingInd].meetingId;
		await Firebaser.removeMeetingFromStorage(meetingIdToRemove);

		const newRecordings = recordings.filter((_, i) => i !== currentRecordingInd);
		Rx.Recordings.next(newRecordings);
		Rx.CurrentRecordingInd.next(Math.max(currentRecordingInd - 1, 0));

		Rx.LoadingReason.next('');
		if (newRecordings.length === 0)
			history.push('/');
	};

	const [allowSend, setAllowSend] = useState(false);
	useEffect(() => {
		setAllowSend(recordings.every(rec =>
			(rec.speakers.length > 0) &&
			!rec.speakers.some(_ => !_ || !_.trim()) &&
			!!rec.name
		));
	}, [recordings]);

	const [audioSources, setAudioSources] = useState<AudioSource[]>([{ name: "Default", src: recordings[currentRecordingInd]?.audio }]);
	useEffect(() => {
		if(audioSources.length === 0 ||
			(!!recordings[currentRecordingInd] && recordings[currentRecordingInd].audio !== audioSources[0].src))
			setAudioSources([{ name: "Default", src: recordings[currentRecordingInd].audio }]);
	}, [recordings, currentRecordingInd, audioSources])

	if (recordings.length <= currentRecordingInd)
		return <></>;

	return (
		<div className='newMeetingPage'>
			<NewMeetingHeader
				name={recordings[currentRecordingInd].name}
				setName={name => {
					recordings[currentRecordingInd].name = name;
					Rx.Recordings.next([...recordings]);
				}}
				date={recordings[currentRecordingInd].date}
				setDate={date => {
					recordings[currentRecordingInd].date = date;
					Rx.Recordings.next([...recordings]);
				}}
				uploadMeeting={uploadMeetingWithoutPay}
				enableUpload={allowSend} />
			<Paper className="newMeetingPaper">
				<div className='removeRecording'>
					<Button onClick={deleteRecording}>
						<Delete />
						<span>מחק את הקלטה</span>
					</Button>
				</div>
				<div className="newMeetingExplaination">
					<span className="title">משתתפים</span>
					<span className='newMeetingDescription' >כדי שהמערכת תוכל לזהות את המשתתפים בפגישה,<br />נבקש ממך לדגום <b>לפחות 45 שניות</b> של כל אחד מהם.</span>
				</div>
				<SpeakersList
					speakers={recordings[currentRecordingInd].speakers}
					addSpeaker={addSpeaker}
					deleteSpeaker={deleteSpeaker}
					changeSpeakerName={changeSpeakerName}
					ranges={recordings[currentRecordingInd].ranges}
					addRange={addRange}
					updateRange={updateRange}
				/>
				{recordings.length > currentRecordingInd &&
					<ColoredPlayer
						ranges={recordings[currentRecordingInd].ranges}
						audioSources={audioSources}
						totalTime={recordings[currentRecordingInd].length}
						updateRange={(newRanges) => {
							recordings[currentRecordingInd].ranges = newRanges;
							Rx.Recordings.next([...recordings]);
						}}
					/>
				}
			</Paper>
		</div>
	);
}
