import * as React from 'react';
import { Slider } from '@material-ui/core';
import SoundManager from '../../services/SoundManager';
import { FC } from 'react';
import { useSubscription } from '../common/useSubscription';
import { Rx } from '../../services/Rx';
import TImeManager from '../../services/TImeManager';

interface SeekBarProps {
	totalTime: number;
}

export const SeekBar: FC<SeekBarProps> = ({ totalTime }) => {
	const currentTime = useSubscription(Rx.CurrentTime);

	const [seekPoint, setSeekPoint] = React.useState(currentTime);
	React.useEffect(() => setSeekPoint(currentTime), [currentTime]);

	const handleSliderChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {
		SoundManager.pause();
		setSeekPoint(value as number);
		event.stopPropagation();
	};

	const handleSliderChangeCommited = (event: React.ChangeEvent<{}>, value: number | number[]) => {
		SoundManager.playOffset(value as number);
	};

	return <Slider
		onChangeCommitted={handleSliderChangeCommited}
		onChange={handleSliderChange}
		min={0}
		max={totalTime}
		marks={!!totalTime ? [{ value: totalTime, label: TImeManager.getTimeStringFromSecs(totalTime, true) }] : []}
		value={seekPoint}
		valueLabelFormat={TImeManager.getTimeStringFromSecs(seekPoint, true)}
		valueLabelDisplay="on"
		style={{ zIndex: -5 }}
	/>
};