import * as React from "react";
import { FC } from 'react';
import { MyMeetings } from "./MyMeetings";
import { NewMeeting } from "./NewMeeting";
import "./Home.css";
import { useSubscription } from "../common/useSubscription";
import { Rx } from "../../services/Rx";
import Firebaser from "../../services/Firebaser";
import { AssignDialog } from "./AssignDialog";
import { AddDialog } from "./AddDialog";
import { DeleteDialog } from "./DeleteDialog";

const loadMeetings = async (userId: string) => {
	if (!!userId) {
		Rx.LoadingReason.next("טוענים מידע כללי");
		await Firebaser.getUserAssignOptions();

		Rx.LoadingReason.next("פותחים לך את יומן הפגישות");
		await Firebaser.getUserMeetings(userId);

		Rx.LoadingReason.next("טוענים הקלטות ממתינות");
		await Firebaser.getWaitingRecordingBatches(userId);

		Rx.LoadingReason.next("");
	}
};

export const MeetingsHome: FC = () => {
	const userId = useSubscription(Rx.ConnectedUserId);
	React.useEffect(() => {
		loadMeetings(userId)
	}, [userId]);
	const editorHomeView = useSubscription(Rx.EditorHomeView);

	return (
		<div className="homeContainer">
			{editorHomeView === 'myMeetings' && <MyMeetings />}
			{editorHomeView === 'newMeeting' && <NewMeeting />}
			<AssignDialog />
			<AddDialog />
			<DeleteDialog />
		</div>
	);
}
