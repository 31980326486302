import * as React from "react";
import { FC } from 'react';
import TImeManager from "../../services/TImeManager";
import { Launch } from "@material-ui/icons";
import "./MeetingItem.css";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Firebaser from "../../services/Firebaser";
import { AssignButton } from "./AssignButton";
import { MeetingPreview } from "../../model/meetingPreview";
import { DeleteButton } from "./DeleteButton";
import { Countdown } from "./Countdown";
import { ReactComponent as Circle } from "../../assets/dot.svg";

interface Props {
	meeting: MeetingPreview;
}

export const MeetingItem: FC<Props> = ({ meeting }) => {
	const history = useHistory();

	const enterMeeting = async () => {
		await Firebaser.getMeeting(meeting.id);
		history.push(`/meeting/${meeting.id}`);
	};

	const [splitId, splitName] = meeting.name.split(" | ");

	return (
		<div className="meetingItem">
			<div className="cellItem goToMeeting"><IconButton onClick={enterMeeting} ><Launch style={{ color: 'rgb(216, 43, 66)' }} /></IconButton></div>
			<span className="cellItem meetingName">
				<span className={`splitName ${splitName && 'ltr'}`} onMouseDown={(event) => {
					event.preventDefault()
					event.stopPropagation()
				}}><a href={`/meeting/${meeting.id}`}>{splitId}</a></span>
				{
					splitName && (
						<span className="splitId"> | {splitName}</span>
					)
				}
			</span>
			<span className="cellItem meetingLength">{meeting.lengthInSecs ? TImeManager.getTimeStringFromSecs(meeting.lengthInSecs) : ""}</span>
			<span className="cellItem meetingDate">{TImeManager.getDateString(meeting.date)}</span>
			<AssignButton meeting={meeting} />
			<Countdown startDate={meeting.date} endDate={meeting.dateOfReady} />
			<span className="cellItem ready"><Circle width={11} height={11} style={{
				margin: "0 3px",
				fill: meeting.dateOfReady ? "#00B300" : "red",
				stroke: meeting.dateOfReady ? "green" : "#7E0000"
			}}/>מוכן לביקורת</span>
			<span className="cellItem sent"><Circle width={11} height={11} style={{
				margin: "0 3px",
				fill: meeting.deliveredToClient ? "#00B300" : "red",
				stroke: meeting.deliveredToClient ? "green" : "#7E0000"
			}}/>נשלח ללקוח</span>
			<DeleteButton meeting={meeting} />
		</div>
	);
}
