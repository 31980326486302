import _ from "lodash";
import "react-draggable-tree/lib/index.css"
import * as React from "react"
import { TreeView as DraggableTreeView, TreeRowInfo, TreeNode } from "../../vendors/react-draggable-tree"
import { MeetingItem } from "./MeetingItem"
import { FC, useState, useEffect } from "react"
import { useSubscription } from "../common/useSubscription"
import { Rx } from "../../services/Rx"
import { Button } from "@material-ui/core"
import { CreateNewFolderOutlined } from "@material-ui/icons"
import { SingleInputDialog } from "../common/SingleInputDialog"
import Firebaser from "../../services/Firebaser"
import { AssignButton } from "./AssignButton"
import { MeetingPreview } from "../../model/meetingPreview"
import { DeleteButton } from "./DeleteButton"

export class TreeMeetingOrFolderItem {
	public minFolderDate: Date | null = null;
	public maxFolderDate: Date | null = null;

	constructor(public folderTitle: string | null,
		public meetingMetadata: MeetingPreview | null,
		public children: TreeMeetingOrFolderItem[] | undefined,
		public collapsed: boolean,
		public key: string|"",
		public totalMeetings: number = 0,
		public readyMeetings: number = 0,
		public deliveredMeetings: number = 0
		) {
	}

	getDescendant(path: number[]): TreeMeetingOrFolderItem | undefined {
		if (path.length === 0) {
			return this
		} else if (this.children) {
			return this.children[path[0]].getDescendant(path.slice(1))
		}
	}

	getAllMeetingsIds(): string[] {
		if(!!this.meetingMetadata)
			return [this.meetingMetadata.id];

		const allChildrenIds = this.children?.flatMap(child => child.getAllMeetingsIds());
		return !allChildrenIds || allChildrenIds.length === 0 ?
			["empty_folder_placeholder"] : allChildrenIds;
	}

	getFolderPath(path: number[]): string[] | null {
		if (path.length === 0)
			return [];

		if (!this.children || !this.children[path[0]] || !this.children[path[0]].folderTitle)
			return null;

		const forwardPath = this.children[path[0]].getFolderPath(path.slice(1));
		if (!forwardPath)
			return null;

		return [this.children[path[0]].folderTitle as string, ...forwardPath]
	}

	flatClone() {
		return new TreeMeetingOrFolderItem(this.folderTitle, this.meetingMetadata, this.children, this.collapsed, this.key);
	}
}

const toTreeNode = (item: TreeMeetingOrFolderItem, collapseMap: {}): TreeNode => {
	//@ts-ignore
	const isCollapsedItem = !_.isNil(collapseMap[item.key]) ? collapseMap[item.key] : item.collapsed;
	return {
		children: isCollapsedItem ? [] : item.children && item.children.map((child) => toTreeNode(child, collapseMap)),
		key: item.key,
		collapsed: isCollapsedItem
	}
}

const updateMeetingsPath = (nodeToMove: TreeMeetingOrFolderItem, newFolderPath: string[]) => {
	const { meetingMetadata, children, folderTitle } = nodeToMove;

	if (!!meetingMetadata) {
		meetingMetadata.folderPath[Rx.ConnectedUserId.value] = newFolderPath.join('/');
		Firebaser.updateFoldersPath(meetingMetadata);
		return;
	}

	if (!children || children.length === 0 || !folderTitle)
		return;

	children.forEach(child => updateMeetingsPath(child, [...newFolderPath, folderTitle as string]))
};

const updateFolderDatesByAddedMeeting = (folder: TreeMeetingOrFolderItem, addedMeetingDate: Date) => {
	if(!folder.minFolderDate || !folder.maxFolderDate){
		folder.minFolderDate = addedMeetingDate;
		folder.maxFolderDate = addedMeetingDate;
		return;
	}

	if(folder.minFolderDate > addedMeetingDate)
		folder.minFolderDate = addedMeetingDate;

	if(folder.maxFolderDate < addedMeetingDate)
		folder.maxFolderDate = addedMeetingDate;
};

const addMeetingToTree = (root: TreeMeetingOrFolderItem, meeting: MeetingPreview) => {
	const foldersPath = !_.isNil(meeting.folderPath.PUBLIC) ? meeting.folderPath.PUBLIC : meeting.folderPath[Rx.ConnectedUserId.value];
	const folderArr = foldersPath ? foldersPath.split("/") : [];

	let currentNode = root;
	currentNode.key = meeting.id;

	for (const folderName of folderArr) {
		updateFolderDatesByAddedMeeting(currentNode, meeting.date);

		if (!currentNode.children)
			currentNode.children = [];

		const nextNode = currentNode.children.find(child => child.folderTitle === folderName);
		if (!!nextNode) {
			nextNode.totalMeetings++
			if (meeting.dateOfReady) nextNode.readyMeetings++
			if (meeting.deliveredToClient) nextNode.deliveredMeetings++
			currentNode = nextNode;
			continue;
		}

		const newFolderInPath = new TreeMeetingOrFolderItem(folderName, null, undefined, true, folderName, 1, meeting.dateOfReady ? 1 : 0, meeting.deliveredToClient ? 1 : 0);
		currentNode.children.push(newFolderInPath);
		currentNode = newFolderInPath;
	}

	updateFolderDatesByAddedMeeting(currentNode, meeting.date);
	if (!currentNode.children)
		currentNode.children = [];
	currentNode.children.push(new TreeMeetingOrFolderItem(null, meeting, undefined, false, meeting.id));
};

const orderTree = (root: TreeMeetingOrFolderItem, compare: (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => number) => {
	if(!root.children)
		return;

	root.children = root.children.sort(compare);
	root.children.forEach(child => orderTree(child, compare));
};

const generateRootFromMeetings = (meetings: MeetingPreview[], compare: (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => number) => {
	const root = new TreeMeetingOrFolderItem(null, null, undefined, false, "");
	meetings.forEach(m => addMeetingToTree(root, m));
	orderTree(root, compare);
	return root;
};

interface MeetingsTreeProps {
	compare: (a: TreeMeetingOrFolderItem, b: TreeMeetingOrFolderItem) => number;
}

export const MeetingsTree: FC<MeetingsTreeProps> = ({ compare }) => {
	const [root, setRoot] = useState<TreeMeetingOrFolderItem>();
	const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set());
	const selectedKeysSize = selectedKeys.size;
	const [collapseMap, setCollapseMap] = useState<any>({});

	const meetings = useSubscription(Rx.Meetings);
	const filteredMeetings = useSubscription(Rx.FilteredMeetings);
	useEffect(() => {
		if (!_.isEmpty(Rx.FilteredMeetings.value)) {
			setRoot(generateRootFromMeetings(filteredMeetings, compare))
		} else {
			setRoot(generateRootFromMeetings(meetings, compare))
		}
	}, [meetings, filteredMeetings, compare]);
	useEffect(() => {
		if (selectedKeysSize > 0)
			return;
		setSelectedKeys(new Set(!!root && !!root.children ? [root.children[0].key] : []))
	}, [root, selectedKeysSize]);

	const renderRow = (row: TreeRowInfo) => {
		if (!root)
			return <></>;

		const item = root.getDescendant(row.path)!
		return <div className='meeting-cell'>
			{!!item.meetingMetadata ?
				<MeetingItem meeting={item.meetingMetadata} /> :
				<>
					<span style={{width: '10em'}}>{item.folderTitle}</span>
					<span style={{width: '10em'}}>סה״כ פגישות - {item.totalMeetings}</span>
					<span style={{width: '10em'}}>מוכנים לביקורת - {item.readyMeetings}</span>
					<span style={{width: '10em'}}>נשלחו ללקוח - {item.deliveredMeetings}</span>
					<AssignButton getMeetingsIds={() => item.getAllMeetingsIds()}/>
					<DeleteButton getMeetingsIds={() => item.getAllMeetingsIds()}/>
				</>
			}
		</div>
	}

	const onSelectedKeysChange = (newselectedKeys: Set<React.ReactText>) => {
		setSelectedKeys(newselectedKeys as Set<string>);
	}

	const onCollapsedChange = (info: TreeRowInfo, collapsed: boolean) => {
		if (!root)
			return;

		root.getDescendant(info.path)!.collapsed = collapsed
		setRoot(root.flatClone());
	}

	const getPathBeforeMove = (movedItemPath: number[], pathAfterMove: number[]) => {
		for (let i = 0; i < movedItemPath.length - 1; i++) {
			if (pathAfterMove[i] !== movedItemPath[i])
				return pathAfterMove;
		}

		if(pathAfterMove.length === movedItemPath.length - 1)
			return pathAfterMove;

		if (movedItemPath[movedItemPath.length - 1] > pathAfterMove[movedItemPath.length - 1])
			return pathAfterMove;

		const pathBeforeMove = [...pathAfterMove];
		pathBeforeMove[movedItemPath.length - 1]++;
		return pathBeforeMove;
	};

	const onMove = (src: TreeRowInfo[], dest: TreeRowInfo, destIndex: number, destPathAfterMove: number[]) => {
		if (!root)
			return;

		const destParentPathbeforeMove = getPathBeforeMove(src[0].path, destPathAfterMove)
		const folderPath = root.getFolderPath(destParentPathbeforeMove.slice(0, -1));

		if (!folderPath) {
			console.error('something went wrong calculating path', destPathAfterMove.slice(0, -1), root);
			return;
		}

		const items: TreeMeetingOrFolderItem[] = []
		for (let i = 0/*src.length - 1*/; i >= 0; --i) {
			const { path } = src[i]
			const index = path[path.length - 1]
			const parent = root.getDescendant(path.slice(0, -1))!

			const [item] = parent.children!.splice(index, 1)
			items.unshift(item)

			updateMeetingsPath(item, folderPath);
		}

		const destItem = root.getDescendant(destPathAfterMove.slice(0, -1))!
		destItem.children!.splice(destPathAfterMove[destPathAfterMove.length - 1], 0, ...items)
		destItem.collapsed = false
		setRoot(root.flatClone());
	}

	const onCopy = (src: TreeRowInfo[], dest: TreeRowInfo, destIndex: number) => {
		return;
	}

	const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
	const newFolder = (name: string) => {
		setNewFolderDialogOpen(false);

		if (!root || !name)
			return;

		const newFolderItem = new TreeMeetingOrFolderItem(name, null, [], true, "");
		root.children = !!root.children ? [newFolderItem, ...root.children] : [newFolderItem];

		setRoot(root.flatClone());
	};

	if (!root)
		return <></>;

	return (
		<>
			<div className='newFolder'>
				<Button onClick={() => setNewFolderDialogOpen(true)}>
					<span>תיקייה חדשה</span>
					<CreateNewFolderOutlined />
				</Button>
			</div>
			<DraggableTreeView
				root={toTreeNode(root, collapseMap)}
				selectedKeys={selectedKeys}
				rowHeight={40}
				className='example-tree'
				rowContent={renderRow}
				onSelectedKeysChange={onSelectedKeysChange}
				onCollapsedChange={(row, collapsed) => {
					setCollapseMap({
						...collapseMap,
						[row.node.key]: collapsed
					})
					onCollapsedChange(row, collapsed)
				}}
				onMove={onMove}
				onCopy={onCopy}
			/>
			<SingleInputDialog buttonText='צור' onInput={newFolder} open={newFolderDialogOpen} title='הוספת תיקייה' placeholder='שם התיקייה' onClose={() => setNewFolderDialogOpen(false)} />
		</>
	);
};
