import React from "react";
import { FC } from 'react';
import { Dialog, DialogContent, MenuItem, MenuList, DialogTitle } from '@material-ui/core';
import WordsManager from "../../services/WordsManager";
import { SingleInputDialog } from "../common/SingleInputDialog";
import "./SpeakerDialog.css";

interface Props {
	open: boolean;
	onClose: () => void;
	speakerOptions: string[];
}

export const SpeakersDialog: FC<Props> = ({ open, onClose, speakerOptions }) => {
	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}

		if(event.key === 'Escape')
			onClose();
	};

	const [addSpeakerOpen, setAddSpeakerOpen] = React.useState(false);
	const changeSpeaker = (speaker: string) => {
		setAddSpeakerOpen(false);
		WordsManager.changeSpeaker(speaker);
		onClose();
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} onKeyDown={onKeyDown}>
				<DialogTitle>שנה דובר</DialogTitle>
				<DialogContent>
					<MenuList autoFocus>
						{speakerOptions.map(speakerOpt =>
							<MenuItem
								key={speakerOpt}
								onClick={() => changeSpeaker(speakerOpt)}
							>
								{speakerOpt}
							</MenuItem>
						)}
						<MenuItem key={"newSpeaker"} onClick={() => setAddSpeakerOpen(true)}>בחר דובר אחר</MenuItem>
					</MenuList>
				</DialogContent>
			</Dialog>

			<SingleInputDialog
				buttonText="הוסף דובר"
				open={addSpeakerOpen}
				placeholder="הכנס שם הדובר החדש"
				onInput={changeSpeaker}
				notConnectionDialog={true}
				onClose={() => setAddSpeakerOpen(false)}
			/>
		</>
	);
}
