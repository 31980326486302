import * as React from "react";
import { FC } from 'react';
import { Rx } from "../../services/Rx";
import "./MyMeetings.css";
import { useSubscription } from "../common/useSubscription";
import { BatchItem } from "./BatchItem";

export const WaitingRecordings: FC = () => {
	const batches = useSubscription(Rx.Batches);

	return batches.length === 0 ?
		<></> :
		(
			<div className="waitingRecordingList">
				<span className="title">הקלטות ממתינות</span>
				<div className='waitingRecordingsHeaders'>
					<b className='meetingDateWaitingHeader'>תאריך</b>
					<b className='costumerNameWaitingHeader'>שם לקוח</b>
					<b className='meetingLengthHeader'>זמן המתנה</b>
					<b className='recordingNameHeader'>שם הקלטה</b>
				</div>
				{batches.map((batch, i) =>
					<BatchItem key={i} batch={batch} />
				)}
			</div>
		);
}
