import * as React from "react";
import { FC } from 'react';
import TImeManager from "../../services/TImeManager";
import { Rx } from "../../services/Rx";
import { Launch } from "@material-ui/icons";
import "./MeetingItem.css";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Firebaser from "../../services/Firebaser";
import { RecordingBatch } from "../../model/recordingBatch";
import { AssignButton } from "./AssignButton";
import { Countdown } from "./Countdown";
import { useSubscription } from "../common/useSubscription";

interface Props {
	batch: RecordingBatch;
}

export const BatchItem: FC<Props> = ({ batch }) => {
	const history = useHistory();
	const userId = useSubscription(Rx.ConnectedUserId);

	const enterBatch = async () => {
		if(batch.algorithm === "unsupervised"){
			Rx.LoadingReason.next('מעבירים לפגישות שלי');
			await Firebaser.moveBatchToMyMeetings(batch);
			await Firebaser.getUserMeetings(userId);
			await Firebaser.getWaitingRecordingBatches(userId);
			Rx.LoadingReason.next('');
		}
		else{
			Rx.LoadingReason.next('טוענים את ההקלטות שנבחרו');
			await Firebaser.syncBatchDataToRecordings(batch.recordingIds);
			Rx.LoadingReason.next('');
			history.push('/new');
		}
	};

	const isDisabled = batch.inProcessing;

	return (
		<div className="meetingItem" style={isDisabled ? {color: "lightgray"}: {}}>
			<div className="cellItem goToMeeting"><IconButton onClick={enterBatch} disabled={isDisabled}><Launch style={{ color: isDisabled ? 'lightgray' : 'rgb(216, 43, 66)' }} /></IconButton></div>
			<span className="cellItem meetingDate">{TImeManager.getDateString(batch.uploadDate)}</span>
			<span className="cellItem meetingOwner">{batch.user}</span>
			<Countdown startDate={batch.uploadDate} />
			<span className="cellItem recordingName">{batch.name}</span>
			<div className="cellItem assign"><AssignButton batch={batch} disabled={isDisabled}/></div>
		</div>
	);
}
