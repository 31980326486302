import * as React from "react";
import { FC } from 'react';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import "./PaymentDialog.css";
import { Rx } from "../../services/Rx";
import { useSubscription } from "../common/useSubscription";
import PriceProvider from "../../services/PriceProvider";
import TImeManager from "../../services/TImeManager";
import { RecordingNameInput } from "./RecordingNameInput";
// @ts-ignore
import { FilePicker } from 'react-file-picker';
import FileUploadManager from "../../services/FileUploadManager";

interface Props {
	onPay: () => Promise<void>;
}

export const PaymentDialog: FC<Props> = ({onPay}) => {
	const recordings = useSubscription(Rx.Recordings);
	const open = useSubscription(Rx.IsPaymentDialogOpen);
	const isDoneUpload = useSubscription(Rx.IsDoneUploading);

	const handleFileChosen = (file: any) => FileUploadManager.handleFileChosen(file, () => {}, false);

	const mediaTypes = useSubscription(Rx.MediaTypes);

	const paymentEnabled = recordings.reduce((prev, curr) => prev && !!curr.name, true);

	return (
		<Dialog open={open} onClose={() => {}} className='paymentDialogPresentation'>
			<DialogContent className="paymentDialog">
				{!isDoneUpload ? (
					<>
						<span className="title">סיכום עסקה</span>

						<div className="recordingsSummary">
							<div className='recordingsNamesInput'>
								{recordings.map((_,i) => <RecordingNameInput recordingInd={i} key={i} />)}
							</div>

							<FilePicker
								extensions={mediaTypes}
								maxSize={2000}
								onChange={handleFileChosen}
								onError={(err: string) => alert(err)} >
								<Button variant='outlined' className='addRecordingButton'>הוסף הקלטה</Button>
							</FilePicker>
						</div>

						<div className='paymentDetails'>
							<div className='detailsTitles'>
								<span>מספר הקלטות:</span>
								<span>אורך כולל:</span>
								<span>מחיר סופי:</span>
							</div>
							<div className='detailsValues'>
								<span>{recordings.length}</span>
								<span>{`${TImeManager.getTimeStringFromSecs(recordings.reduce((prevSum, curRecording) => prevSum + curRecording.length, 0))}`}</span>
								<span>{`${PriceProvider.getPrice(recordings.map(_ => _.length))} ש"ח (כולל מע"מ)`}</span>
							</div>
						</div>
						<Button
							onClick={() => onPay()}
							variant={"outlined"}
							disabled={!paymentEnabled}
							id="dialogButton">מעבר לתשלום</Button>
						<div className="offerDetail">
							<span>מצאתם הצעה טובה משלנו?</span> <br />
							<b>נשווה אותה ובנוסף תקבלו 20 ש"ח מתנה לשימוש הבא שלכם!</b>
						</div>
					</>
				) : (
					<div className="whatsappContent">
						<div className="title">אנא צרו עמנו קשר</div>
						<div className='speakWithAgent'>
							<a href='https://api.whatsapp.com/send?phone=972525758433&text=היי סאמיט, ביצעתי הזמנה...' target="_blank" rel="noopener noreferrer">
								<span>לחץ למעבר לשיחה עם נציג</span>
							</a>
						</div>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}
