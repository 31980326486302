import React from "react";
import { FC } from 'react';
import { Dialog, DialogContent, Button } from '@material-ui/core';
import "./AssignDialog.scss";
import { useSubscription } from "../common/useSubscription";
import { Rx } from "../../services/Rx";
import Firebaser from "../../services/Firebaser";

export const DeleteDialog: FC = () => {
	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}

		if(event.key === 'Escape')
			onClose();
	};

	const meetingsToDelete = useSubscription(Rx.MeetingsToDelete);
	const onClose = () => Rx.MeetingsToDelete.next([]);

	const approveDelete = () => {
		Firebaser.deleteMeetings(meetingsToDelete.filter(_ => _ !== "empty_folder_placeholder"));
		onClose();
	};

	return (
		<>
			<Dialog open={meetingsToDelete.length > 0} onClose={onClose} onKeyDown={onKeyDown}>
				<DialogContent style={{margin: 'unset auto', display:'flex', flexDirection:'column'}}>
					<span>האם אתה בטוח שברצונך למחוק?</span>
					<Button id="dialogButton" onClick={approveDelete}>אשר מחיקה</Button>
				</DialogContent>
			</Dialog>
		</>
	);
}
