import * as React from "react";
import { FC, useCallback } from 'react';
import './ColoredSeekBar.css'
import { animated } from "react-spring";
import { useGesture } from "react-use-gesture";
import { ContextMenuTrigger, MenuItem, ContextMenu } from "react-contextmenu";
import { SeekBar } from "./SeekBar";

export interface Range {
	start: number;
	end?: number;
	color: string;
	speaker: string;
}

interface PaddedRange {
	start: number;
	end: number;
	color: string;
	speaker: string;
	widthPixels: number;
	originalIndex: number;
}

interface Props {
	TOTAL_WIDTH: number;
	totalTime: number;
	ranges: Range[];
	rangeClicked: (index: number) => void;
	updateRange?: (ranges: Range[]) => void;
}

export const ColoredSeekBar: FC<Props> = ({ TOTAL_WIDTH, totalTime, ranges, rangeClicked, updateRange }) => {
	const getWidthForRanges = useCallback((paddedRanges: PaddedRange[]) => {
		return paddedRanges.map((range, rangeIndex) => {
			const inputRangeEnd = paddedRanges[rangeIndex].end;

			const rangeEnd = !!inputRangeEnd ?
				inputRangeEnd :
				(rangeIndex === paddedRanges.length - 1 ? totalTime : paddedRanges[rangeIndex + 1].start);

			const length = TOTAL_WIDTH * (rangeEnd - paddedRanges[rangeIndex].start) / totalTime;
			return { ...range, widthPixels: length };
		});
	}, [totalTime, TOTAL_WIDTH]);

	const [paddedRanges, setPaddedRanges] = React.useState<PaddedRange[]>([]);

	const padRangesWithVoid = useCallback((rawRanges: Range[]) => {
		const paddedRanges: PaddedRange[] = [];

		const firstVoidEnd = rawRanges.length === 0 ? totalTime : rawRanges[0].start;
		paddedRanges.push({
			start: 0,
			end: firstVoidEnd,
			speaker: "void",
			color: "transparent",
			originalIndex: -1,
			widthPixels: -1
		});

		rawRanges.forEach((range, i) => {
			const rangeEnd = range.end || (i === rawRanges.length - 1 ? totalTime : rawRanges[i + 1].start);

			paddedRanges.push({
				...range,
				end: rangeEnd,
				originalIndex: i,
				widthPixels: -1
			});

			const voidRangeEnd = i === rawRanges.length - 1 ? totalTime : rawRanges[i + 1].start;

			paddedRanges.push({
				start: rangeEnd,
				end: voidRangeEnd,
				speaker: "void",
				color: "transparent",
				originalIndex: -1,
				widthPixels: -1
			});
		});

		setPaddedRanges(getWidthForRanges(paddedRanges));
	}, [setPaddedRanges, totalTime, getWidthForRanges]);

	const erase = (originalIndex: number) => {
		!!updateRange && updateRange(ranges.filter((_, i) => i !== originalIndex));
	};

	React.useEffect(() => padRangesWithVoid(ranges), [ranges, padRangesWithVoid]);

	const [move, setMove] = React.useState<{ offset: number, isToRight: boolean, originalRangeIndex: number }>({ offset: 0, isToRight: false, originalRangeIndex: -1 });
	const bind = useGesture({
		onDrag: ({ args: [originalIndex, isToRight], down, delta: [x,] }) => {
			let secChange = getSecsFromPixels(move.offset + x);

			const rightBoundry = originalIndex === ranges.length - 1 ? totalTime : ranges[originalIndex + 1].start;
			const leftBoundry = originalIndex === 0 ? 0 : (ranges[originalIndex - 1].end || 0);

			if (isToRight && ((ranges[originalIndex].end || 0) + secChange > rightBoundry))
				secChange = rightBoundry - (ranges[originalIndex].end || 0);

			if (isToRight && ((ranges[originalIndex].end || 0) + secChange < ranges[originalIndex].start))
				secChange = ranges[originalIndex].start - (ranges[originalIndex].end || 0);

			if (!isToRight && ((ranges[originalIndex].start + secChange < leftBoundry)))
				secChange = leftBoundry - ranges[originalIndex].start;

			if (!isToRight && (ranges[originalIndex].start + secChange > (ranges[originalIndex].end || 0)))
				secChange = (ranges[originalIndex].end || 0) - ranges[originalIndex].start;

			const newMove = { originalRangeIndex: originalIndex, isToRight: isToRight, offset: getPixelsFromSecs(secChange) };
			setMove(newMove);

			if (!down) {
				const newRanges: Range[] = [...ranges];

				if (isToRight)
					newRanges[originalIndex].end = (newRanges[originalIndex].end || 0) + secChange;
				else
					newRanges[originalIndex].start = newRanges[originalIndex].start + secChange;

				setMove({ offset: 0, isToRight: false, originalRangeIndex: -1 });
				!!updateRange && updateRange(newRanges);
			}
		}
	});

	const getSecsFromPixels = (pixels: number) => {
		return pixels * totalTime / TOTAL_WIDTH;
	};

	const getPixelsFromSecs = (secs: number) => {
		return secs * TOTAL_WIDTH / totalTime;
	};

	const getRangeWidth = (rangeIndex: number) => {
		const widthChange = move.offset * (move.isToRight ? 1 : -1);

		if (rangeIndex > 0 && paddedRanges[rangeIndex - 1].originalIndex === move.originalRangeIndex && move.isToRight)
			return `${paddedRanges[rangeIndex].widthPixels - widthChange}px`;

		if (rangeIndex < paddedRanges.length - 1 && paddedRanges[rangeIndex + 1].originalIndex === move.originalRangeIndex && !move.isToRight)
			return `${paddedRanges[rangeIndex].widthPixels - widthChange}px`;

		return paddedRanges[rangeIndex].originalIndex !== -1 &&
			paddedRanges[rangeIndex].originalIndex === move.originalRangeIndex ?
			`${paddedRanges[rangeIndex].widthPixels + widthChange}px` :
			`${paddedRanges[rangeIndex].widthPixels}px`
	};

	return (
		<div className="coloredSeekBar" style={{ width: TOTAL_WIDTH }}>
			<div className="colorsBar">
				{paddedRanges.map((range, i) =>
					<animated.div className={range.originalIndex > -1 ? "coloredRange" : "padding"}
						style={{
							width: getRangeWidth(i),
							height: "80%",
							position: 'relative',
							display: 'flex',
							flexDirection: 'row'
						}}
						key={i}>
						{range.originalIndex > -1 &&
							<>
								<ContextMenuTrigger
									disable={!updateRange}
									id={"delete_range-" + range.originalIndex}
								>
									<animated.div
										{...bind(range.originalIndex, false)}
										style={{
											backgroundColor: range.color,
											width: "2px",
											height: "160%",
											cursor: 'e-resize',
											position: 'absolute',
											left: '0',
											bottom: '0',
											visibility: !!updateRange ? 'visible' : 'hidden',
										}}
									>
									</animated.div>

									<div
										style={{
											backgroundColor: range.color,
											width: "100%",
											height: "100%",
											cursor: 'pointer'
										}}
										onClick={() => range.originalIndex > -1 && rangeClicked(range.originalIndex)}>
									</div>
									<animated.div
										{...bind(range.originalIndex, true)}
										style={{
											backgroundColor: range.color,
											width: "2px",
											height: "160%",
											cursor: 'e-resize',
											position: 'absolute',
											right: '0',
											bottom: '0',
											visibility: !!updateRange ? 'visible' : 'hidden',
										}}
									>
									</animated.div>
								</ContextMenuTrigger>
								<ContextMenu id={"delete_range-" + range.originalIndex}>
									<MenuItem onClick={() => erase(range.originalIndex)}>מחק</MenuItem>
								</ContextMenu>
							</>
						}
					</animated.div>
				)}
			</div>
			<SeekBar totalTime={totalTime} />
		</div>
	);
}
