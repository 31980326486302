import * as React from 'react';
import { FC } from 'react';
import './Information.css';
import { Paper } from '@material-ui/core';
import appStore from '../../assets/available-on-iphone-app-store.png';
import playStore from '../../assets/get-it-on-google-play.png';
import phoneHolding from '../../assets/phone-holding.jpg';
import { Rx } from '../../services/Rx';

export const Information: FC = () => {
	React.useEffect(() => Rx.LoadingReason.next(''), []);

	return < Paper className="infoContainer" elevation={10} square >
		<div className="bigTitle">
			ברוכים הבאים<br /> לחברת התמלול Sumit
		</div>
		<div className="infoAndPhoto">
			<img src={phoneHolding} alt="" ></img>
			<div className="allInfo">
				<span className='infoTitle'>אנחנו יודעים...</span>
				<div className="infoSection" >
					<div className="orange" ></div>
					<div>
						אנחנו יודעים - בסך הכל רציתם לתמלל הקלטה קצרה לטובת עדות בביה"ש, וגיליתם שזה כל כך יקר.. אז זהו, שלא! <br />
			בעזרת הטכנולוגיה שפיתחנו, אנחנו מסוגלים לבצע את משימות התמלול ביעילות ובמהירות,	כמעט ללא מגע יד אדם,
			<br /> ולכן אנחנו יכולים להציע את השירות שלנו במחיר הטוב ביותר בשוק.
		</div>
				</div>
				<span className='infoTitle'>קצת עלינו</span>
				<div className="infoSection" >
					<div className="orange" ></div>
					<div>
						חברת Sumit נוסדה ע"י שלושה יזמים צעירים - אשר, שלומי ובר, בעלי רקע בהנדסת תוכנה ומשפטים, <br />
			על מנת להציע שירות דיגיטלי חדשני - כזה שניתן להפעיל בכל זמן ומקום. <br />
			אנחנו שואפים לתת את השירות הזול, המדויק והמהיר ביותר, מבלי להתפשר על האיכות. <br />
			לכן, אם ישנה בעיה/שאלה/סתם התעניינות, נשמח לשמוע מכם.
		</div>
				</div>
				<span className='infoTitle'>וזה לא הכל!</span>
				<div className="infoSection" >
					<div className="orange" ></div>
					<div>
						פיתחנו גם אפליקציות ייעודיות שתכליתן לספק שירות עצמאי, כך שלא תצטרכו להעלות <br />
			את הקבצים בעצמכם, אלא שאלו יעלו ויתומללו אוטומטית! <br />
			כמו כן, בעזרת האפליקציות, אנחנו יודעים לתת שירות איכותי גם לפגישות מרובות משתתפים!
		</div>
				</div>
			</div>
		</div>
		<div className='downloadOptions'>
			<a href='https://apps.apple.com/il/app/sumit-app/id1494412174' target="_blank" rel="noopener noreferrer"><img src={appStore} alt="" /></a>
			<a href='https://play.google.com/store/apps/details?id=com.sumit_ai' target="_blank" rel="noopener noreferrer"><img src={playStore} alt="" /></a>
		</div>
	</ Paper>;
}