import * as React from "react";
import { FC, useState } from 'react';
import { Add } from "@material-ui/icons";
import "./NewMeeting.css";
import { Button, FormControlLabel, Paper, Switch, TextField } from "@material-ui/core";
// @ts-ignore
import { FilePicker } from 'react-file-picker';
import { useHistory } from "react-router-dom";
import { Rx } from "../../services/Rx";
import FileUploadManager from "../../services/FileUploadManager";
import { WaitingRecordings } from "./WaitingRecordings";
import { useSubscription } from "../common/useSubscription";
import Firebaser from "../../services/Firebaser";
import { RangesInfo } from "../../model/rangesInfo";

export const NewMeeting: FC = () => {
	const history = useHistory();
	const userId = useSubscription(Rx.ConnectedUserId);
	const budget = useSubscription(Rx.Budget);

	const uploadSupervisedAsync = async (rangesInfo: RangesInfo) => {
		Rx.LoadingReason.next("שומרים את המידע שלך");

		await Firebaser.uploadSupervised(rangesInfo);

		Rx.LoadingReason.next("");

		history.push('/new');
	};

	const uploadUnupervisedAsync = async (rangesInfo: RangesInfo) => {
		Rx.LoadingReason.next("שומרים את המידע שלך");

		await Firebaser.uploadUnsupervised(rangesInfo, true);
		await Firebaser.getWaitingRecordingBatches(userId);

		Rx.Recordings.next([]);

		Rx.LoadingReason.next("");
	};

	const handleFileChosen = (file: any) => {
		FileUploadManager.handleFileChosen(file, async () => {
			const recording = Rx.Recordings.value[0];
			recording.name = recordingName;

			const rangesInfo: RangesInfo = {
				meetingId: recording.meetingId,
				groupId: Firebaser.generateID(),
				meetingDate: recording.date,
				meetingName: recording.name,
				participants: [],
				meetingLengthInSec: recording.length
			};
			setRecordingName('');

			isUnsupervised ? await uploadUnupervisedAsync(rangesInfo) : await uploadSupervisedAsync(rangesInfo);
		});
	};

	const [recordingName, setRecordingName] = useState("");
	const [isUnsupervised, setIsUnsupervised] = useState(false);

	const mediaTypes = useSubscription(Rx.MediaTypes);

	return (
		<Paper className="newMeeting">
			<span className="title">פגישה חדשה</span>
			<span className='newMeetingDescription' >פה אפשר להעלות קובץ של פגישה חדשה <br /> ששמורה אצלך במחשב ולערוך אותה</span>
			<TextField
				value={recordingName}
				onChange={e => setRecordingName(e.target.value as string)}
				variant='outlined'
				placeholder='שם ההקלטה'
				style={{marginBottom: '0.5em'}}
				disabled={!budget}
			/>
			<div style={{display:'flex', flexDirection:'row', alignItems: 'center'}}>
				<FilePicker
					extensions={mediaTypes}
					maxSize={2000}
					onChange={handleFileChosen}
					onError={(err: string) => alert(err)} >
					<Button disabled={!recordingName || !budget} variant={"outlined"} ><Add /><span>העלאת קובץ אודיו</span></Button>
				</FilePicker>
				<FormControlLabel
					control={
						<Switch checked={isUnsupervised}
								onChange={(_, checked) => setIsUnsupervised(checked)} />
					}
					label="Unsupervised"
					labelPlacement='top'
				/>
			</div>
			{!budget && (
				<div style={{color: "red"}}>
					<span>לא נותר באדג׳ט להעלאת הקלטות חדשות</span>
					<div className="speakWithAgent">
						<a href='https://api.whatsapp.com/send?phone=972525758433&text=היי סאמיט, אני רוצה להוסיף באדג׳ט...' target="_blank" rel="noopener noreferrer">
							<span>לחץ למעבר לשיחה עם נציג</span>
						</a>
					</div>
				</div>
			)}
			<WaitingRecordings />
		</Paper>
	);
}
