import * as React from "react";
import {FC, MutableRefObject, useCallback} from 'react';
import { IconButton, MenuItem, Menu } from "@material-ui/core";
import {
	PlayArrowOutlined, Replay5, Forward5,
	SkipPrevious, SkipNext, PauseOutlined,
	QueueMusic, Timer, SaveAlt
} from "@material-ui/icons";
import { ColoredSeekBar, Range } from "./ColoredSeekBar";
import "./ColoredPlayer.css";
import SoundManager from "../../services/SoundManager";
import { Rx } from "../../services/Rx";
import { useSubscription } from "../common/useSubscription";
import { AudioSource } from "../../model/meeting";
import { useDrag } from 'react-use-gesture';
import { useSpring, animated, interpolate, config } from 'react-spring';
// @ts-ignore
import { saveAs } from "file-saver";

interface Props {
	audioSources: AudioSource[];
	ranges: Range[];
	totalTime: number;
	updateRange?: (updatedRanges: Range[]) => void;
	isVideo?: boolean;
}

export const ColoredPlayer: FC<Props> = ({ audioSources, ranges, totalTime, updateRange, isVideo= false }) => {
	const isPlaying = useSubscription(Rx.IsPlaying);

	const [audioIndex, setAudioIndex] = React.useState(0);
	React.useEffect(() => {
		if (!isVideo) SoundManager.initializeMedia(audioSources[audioIndex].src, audioSources[audioIndex].offset)
	}, [audioIndex, audioSources]);

	const [speed, setSpeed] = React.useState(1);
	React.useEffect(() => SoundManager.changeSpeed(speed), [speed]);

	const downloadAudio = () => {
		const audioSource = audioSources[audioIndex];
		if (!audioSource) {
			alert("לא נמצא קובץ שמע לניגון :(");
			return;
		}

		var xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onload = function (event) {
			var blob = xhr.response;
			saveAs(blob, audioSource.name);
			Rx.LoadingReason.next('');
		};
		xhr.open('GET', audioSource.src);
		Rx.LoadingReason.next('המתן מבלי לסגור את החלון');
		xhr.send();
	};

	const getCurrentRangeIndex = () => {
		const firstNotStartedYet = ranges.findIndex(r => r.start > Rx.CurrentTime.value);

		if (firstNotStartedYet < 0)
			return ranges.length - 1;

		return firstNotStartedYet - 1;
	}

	const rangeClicked = (index: number) => {
		SoundManager.playOffset(ranges[index].start);
	}

	const previousRange = () => {
		const index = getCurrentRangeIndex() - 1;
		if (index > -1) SoundManager.playOffset(ranges[index].start);
	};

	const nextRange = () => {
		const index = getCurrentRangeIndex() + 1;
		if (index < ranges.length) {
			const rangeStartTime = ranges[index].start;
			SoundManager.playOffset(ranges[index].start);
		}
	};

	const goBack5 = () => {
		SoundManager.playRelative(-5)
	};

	const goNext5 = () => {
		SoundManager.playRelative(5)
	};

	const play = () => {
		SoundManager.play();
	};

	const pause = () => {
		SoundManager.pause();
	};

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [speedAnchorEl, setSpeedAnchorEl] = React.useState(null);

	const [{ x, y }, setOffset] = useSpring(() => ({ x: 0, y: 0, config: config.stiff }));
	const bind = useDrag(({ offset: [x, y] }) => {
		!updateRange && setOffset({ x, y });
	});

	const width = !updateRange ? "28em" : "85vw";
	const [seekBarWidth, setSeekBarWidth] = React.useState(!updateRange ? 400 : window.innerWidth * 0.8);

	const handleWindowResize = useCallback(() =>
		!!updateRange && setSeekBarWidth(window.innerWidth * 0.8), [updateRange, setSeekBarWidth]);
	React.useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, [handleWindowResize]);

	return (
		<animated.div
			className="coloredPlayerWrapper"
			{...bind()}
			style={{ transform: interpolate([x, y], (dx, dy) => `translate(${dx}px, ${dy}px)`) }}
		>
			<div>
				<IconButton
					aria-label="more"
					aria-controls="long-menu"
					aria-haspopup="true"
					// @ts-ignore
					onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
					style={{ backgroundColor: 'white', color: "#FFBD1E" }}
					className='playerButtonOnComputer'
				>
					<Timer />
				</IconButton>
				{!isPlaying ?
					<IconButton onClick={play}
						style={{ backgroundColor: 'white', color: "#FFBD1E" }}
						className='playerButtonOnMobile'>
							<PlayArrowOutlined />
					</IconButton>
					: <IconButton onClick={pause}
						style={{ backgroundColor: 'white', color: "#FFBD1E" }}
						className='playerButtonOnMobile'>
							<PauseOutlined />
					</IconButton>
				}
				<Menu
					open={!!speedAnchorEl}
					anchorEl={speedAnchorEl}
					keepMounted
					onClose={() => setSpeedAnchorEl(null)}
					defaultValue={speed}
					PaperProps={{
						style: {
							maxHeight: '10em'
						}
					}}
				>
					{[0.5, 0.75, 1, 1.25, 1.5, 2].map(rate =>
						<MenuItem
							key={`${rate}`}
							value={rate}
							style={{ backgroundColor: rate === speed ? "#FFBD1E" : "white" }}
							onClick={() => { setSpeedAnchorEl(null); setSpeed(rate); }}
						>{rate}</MenuItem>
					)}
				</Menu>
			</div>
			<div className="coloredPlayer" style={{ width }}>
				<ColoredSeekBar
					TOTAL_WIDTH={seekBarWidth}
					totalTime={totalTime}
					ranges={ranges}
					rangeClicked={rangeClicked}
					updateRange={updateRange}
				/>
				<div className="playerControls">
					<IconButton onClick={previousRange}><SkipPrevious /></IconButton>
					<IconButton onClick={goBack5}><Replay5 /></IconButton>
					{!isPlaying ?
						<IconButton onClick={play} className='playerButtonOnComputer'><PlayArrowOutlined /></IconButton>
						: <IconButton onClick={pause} className='playerButtonOnComputer'><PauseOutlined /></IconButton>
					}
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						// @ts-ignore
						onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
						className='playerButtonOnMobile'
					>
						<Timer />
					</IconButton>
					<IconButton onClick={goNext5}><Forward5 /></IconButton>
					<IconButton onClick={nextRange}><SkipNext /></IconButton>
				</div>
			</div>
			{!updateRange &&
				<div>
					<IconButton
						onClick={downloadAudio}
						style={{ backgroundColor: 'tranparent', color: "white" }}
					>
						<SaveAlt />
					</IconButton>

					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						// @ts-ignore
						onClick={(e) => setAnchorEl(e.currentTarget)}
						style={{ backgroundColor: 'white', color: "#FFBD1E" }}
					>
						<QueueMusic />
					</IconButton>

					<Menu
						open={!!anchorEl}
						anchorEl={anchorEl}
						keepMounted
						onClose={() => setAnchorEl(null)}
						defaultValue={audioIndex}
						PaperProps={{
							style: {
								maxHeight: '10em'
							}
						}}
					>
						{audioSources.map((a, i) =>
							<MenuItem
								key={i}
								value={i}
								style={{ backgroundColor: audioIndex === i ? "#FFBD1E" : "white" }}
								onClick={() => { setAnchorEl(null); setAudioIndex(i); }}
							>{a.name}</MenuItem>
						)}
					</Menu>
				</div>
			}
		</animated.div >
	);
}
