class PriceProvider {
    private static instance: PriceProvider;

    private constructor() { }

    public static getInstance = () => {
        if (!PriceProvider.instance)
            PriceProvider.instance = new PriceProvider();

        return PriceProvider.instance;
    }

    public getPriceForSecs = (secs: number) => {
        if (secs === 0)
            return 0;

        const mins = secs / 60;

        if (mins <= 7)
            return 120;

        if (mins <= 90)
            return 120 + Math.round((mins - 7) * 8);

        return 120 + 90 * 8 + Math.round((mins - 90) * 7);
    };

    public getPrice = (lengths: number[]) => {
        const totalSecs = lengths.reduce((prevSum, cur) => prevSum + cur, 0);
        const notRoundedPrice = this.getPriceForSecs(totalSecs) * 1.17;
	    return Math.round(notRoundedPrice * 100) / 100;
    };
}

export default PriceProvider.getInstance();