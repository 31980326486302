import React, { FC, useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useSubscription } from '../common/useSubscription';
import { Rx } from '../../services/Rx';
import './RecordingNameInput.css';
import { DashboardRounded } from '@material-ui/icons';

interface Props {
	recordingInd: number;
}

export const RecordingNameInput: FC<Props> = ({ recordingInd }) => {
	const recording = useSubscription(Rx.Recordings)[recordingInd];

	const [name, setName] = useState(recording.name);

	useEffect(() => {
		const newRecordings = [...Rx.Recordings.value];
		newRecordings[recordingInd].name = name;
		Rx.Recordings.next(newRecordings);
	}, [name, recordingInd]);

	return (
		<div className='recordingNameInput'>
			<DashboardRounded />
			<TextField 
				variant='outlined' 
				value={name} 
				onChange={(e) => setName(e.target.value)} 
				placeholder='חובה למלא שדה זה' 
				InputProps={{ 
					classes: {
						input: 'errorPlaceHolder'
					}	
				}} 
				label='שם הקלטה'
				InputLabelProps={{
					shrink: true,
				}}
			/>
		</div>
	)
};