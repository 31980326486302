import React, { useEffect } from 'react';
import _ from "lodash";
import {FC, MutableRefObject} from 'react';
import { useSubscription } from '../common/useSubscription';
import {Word} from "../../model/word";
import { Rx } from '../../services/Rx';
import Draggable from 'react-draggable';

import "./VideoPlayer.scss";
import SoundManager from "../../services/SoundManager";
import useEventCallback from "@material-ui/core/utils/useEventCallback";

const videoContainerId = "videoPlayerContainer";

interface VideoPlayerProps {
	currentRangeWords: Word[][];
	videoSources: any[]
}

export const VideoPlayer: FC<VideoPlayerProps> = ({ videoSources, currentRangeWords }) => {
	const ltr = useSubscription(Rx.LTR);
	useEffect(() => {
		const defaultVideo = videoSources.find((video) => video.name === "Default");
		SoundManager.initializeMedia(defaultVideo.src, 0, videoContainerId)
	}, [])

	// const currentTime = useSubscription(Rx.CurrentTime);
	// const currentWordIndex = _.findIndex(currentRangeWords, (word) => word.start >= currentTime);
	// const passedWords = currentRangeWords.slice(0, currentWordIndex).map(word => word.text).join(" ");
	// const nextWords = currentRangeWords.slice(currentWordIndex, currentRangeWords.length-1).map(word => word.text).join(" ");
	const rangeStrings = currentRangeWords.map(range => range.map(word => word.text).join(" "));

	return (
		<Draggable
			handle=".VideoPlayerComponent"
			scale={1}>
			<div className="VideoPlayerComponent">
				<div>
					<div className={videoContainerId} id={videoContainerId}>
						{/*Video is injected here by SoundManager*/}
						<div className="videoSubtitles">
							{/*<span className="passedWords">{passedWords}</span> <span>{nextWords}</span>*/}
							{rangeStrings.map(string => (
								<span className={`rangeString ${ltr ? "ltr " : ""}${rangeStrings.length > 1 ? "overlapping" : ""}`}>{string}</span>
							))}
						</div>
					</div>
				</div>
			</div>
		</Draggable>
	);
};
