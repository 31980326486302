class TimeManager {
    private static instance: TimeManager;

    private constructor() { }

    public static getInstance = () => {
        if (!TimeManager.instance)
            TimeManager.instance = new TimeManager();

        return TimeManager.instance;
    }

    public getTimeStringFromSecs = (numOfSecs: number, onlyMins: boolean = false, withMilli = false) => {
        if (onlyMins)
            return this.get2DigitNumber(numOfSecs / 60) + ":" + this.get2DigitNumber(numOfSecs % 60);

        const milliseconds = (numOfSecs % 1).toFixed(3).substring(2)
        const seconds = numOfSecs % 60;
        const minutes = (numOfSecs / 60) % 60;
        const hours = numOfSecs / 3600;
        return this.get2DigitNumber(hours) + ":" + this.get2DigitNumber(minutes) + ":" + this.get2DigitNumber(seconds) + (withMilli ? ("." + milliseconds) : "");
    };

    public getTimeNumberFromString = (time: string) => {
        const [hours = 0, minutes = 0, seconds = 0] = time.split(":");
        const hourSeconds = Number(hours) * 3600;
        const minutesSeconds = Number(minutes) * 60;

        return hourSeconds + minutesSeconds + Number(seconds);
    };

    private get2DigitNumber = (n: number) => {
        const floored = Math.floor(n);
        if (floored < 10)
            return `0${floored}`;
        return floored;
    };

    public getDateString = (date: Date) => {
        return this.get2DigitNumber(date.getDate()) + "/" + this.get2DigitNumber(date.getMonth() + 1) + "/" + this.get2DigitNumber(date.getFullYear());
    };

    public compareDates = (a: Date, b: Date) => {
        if (a.getFullYear() !== b.getFullYear())
            return a.getFullYear() - b.getFullYear();

        if (a.getMonth() !== b.getMonth())
            return a.getMonth() - b.getMonth();

        if (a.getDate() !== b.getDate())
            return a.getDate() - b.getDate();

        return 0;
    }
}

export default TimeManager.getInstance();
