import React, { FC, useState } from 'react';
import { TextField, IconButton, makeStyles } from '@material-ui/core';
import { Check, AddCircle, Delete } from '@material-ui/icons';
import IndexedListColorManager from '../../services/IndexedListColorManager';
import './SpeakerItem.css';
import { RangePicker } from './RangePicker';
import { Rx } from '../../services/Rx';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const useStyles = makeStyles({
	foo: (props: { color: string }) => ({
		'& .dot': {
			backgroundColor: props.color + ' !important'
		},
		'& .rangePicker': {
			border: `2px solid ${props.color} !important`
		}
	})
});

export interface ThinRange {
	start: number;
	end: number;
}

interface Props {
	index: number;
	name: string;
	setName: (newName: string) => void;
	deleteSpeaker: () => void;
	totalSecs: number;
	savedRanges: ThinRange[];
	addRange: (newRange: ThinRange) => void;
	updateRange: (index: number, newRange?: ThinRange) => void;
}

export const SpeakerItem: FC<Props> = ({ index, name, setName, deleteSpeaker, totalSecs, savedRanges, addRange, updateRange }) => {
	const doneSecs = totalSecs || 0;
	const color = IndexedListColorManager.getColorByIndex(index);

	const [addedRanges, setAddedRanges] = useState<ThinRange[]>([]);

	const newAddedRange = () => {
		setAddedRanges([...addedRanges, { start: -1, end: -1 }]);
	};

	const handleRangeChange = (index: number, newRange?: ThinRange) => {
		if (!newRange) {
			if (index < savedRanges.length)
				updateRange(index);
			else
				setAddedRanges(addedRanges.filter((_, i) => i !== index - savedRanges.length));
			return;
		}

		if (newRange.start !== -1 && newRange.end !== -1 &&
			(newRange.start >= newRange.end || newRange.start < 0 || newRange.end > Rx.Recordings.value[Rx.CurrentRecordingInd.value].length)) {
			alert('טווחי זמנים לא חוקיים!');
			return;
		}

		if (index < savedRanges.length) {
			updateRange(index, newRange);
			return;
		}

		if (newRange.start !== -1 && newRange.end !== -1) {
			setAddedRanges(addedRanges.filter((_, i) => i !== index - savedRanges.length));
			addRange(newRange);
			return;
		}

		const newAddedRanges = [...addedRanges];
		newAddedRanges[index - savedRanges.length] = newRange;
		setAddedRanges(newAddedRanges);
	}

	const classes = useStyles({ color });

	const shouldShowV = doneSecs >= 45;

	return (
		<div className='speakerItem'>
			<Check style={{ color: 'green', visibility: shouldShowV ? 'visible' : 'hidden' }} />
			<div className='speakerItemWithoutCheck'>
				<div className='speakerData'>
					<span style={{ color: color }}>{`שם משתתף ${index + 1}`}</span>
					<TextField variant='outlined' value={name} onChange={(e) => setName(e.target.value)}
						placeholder='חובה למלא שדה זה'
						InputProps={{
							classes: {
								input: 'errorPlaceHolder'
							}
						}}/>
					<span>{`עד כה סימנת ${Math.floor(doneSecs)} שניות`}</span>
				</div>
				<IconButton onClick={deleteSpeaker}><Delete style={{ color: color }} /></IconButton>
				<div>
					<IconButton onClick={newAddedRange} disabled={!name}><AddCircle style={{ color: color }} /></IconButton>
					<span>דגימה</span>
				</div>

				<Carousel width={260} className={classes.foo}>
					{[...savedRanges, ...addedRanges].map((r, i) =>
						<RangePicker key={i} range={r} updateRange={(newR => handleRangeChange(i, newR))} color={color} />
					)}
				</Carousel>
			</div>
		</div>
	);
};
