import React, { FC, useState, useEffect } from 'react';
import { SpeakerItem, ThinRange } from './SpeakerItem';
import { Range } from '../EditorPage/ColoredSeekBar';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import './SpeakersList.css';

interface Props {
	speakers: string[];
	changeSpeakerName: (index: number, newName: string) => void;
	deleteSpeaker: (index: number) => void;
	addSpeaker: () => void;

	ranges: Range[];
	updateRange: (rangeIndex: number, newRange?: Range) => void;
	addRange: (speakerIndex: number, newRange: ThinRange) => void;
}

export const SpeakersList: FC<Props> = ({ speakers, changeSpeakerName, deleteSpeaker, addSpeaker, ranges, addRange, updateRange }) => {
	const [totalTimes, setTotalTimes] = useState<{ [speaker: string]: number }>({});
	const [speakerRanges, setSpeakerRanges] = useState<{ [speaker: string]: ThinRange[] }>({});
	useEffect(() => {
		const newTimes: { [speaker: string]: number } = {};

		ranges.forEach((range) => {
			const previousTime: number = newTimes[range.speaker] || 0;
			const rangeEnd = range.end || range.start;
			newTimes[range.speaker] = previousTime + rangeEnd - range.start;
		});

		setTotalTimes(newTimes);
	}, [ranges]);

	useEffect(() => {
		const newspeakerRanges: { [speaker: string]: ThinRange[] } = {};

		speakers.forEach((_, i) => newspeakerRanges[`${i}`] = []);

		ranges.forEach((range) => {
			const rangeEnd = range.end || range.start;
			newspeakerRanges[range.speaker].push({ start: range.start, end: rangeEnd });
		});

		setSpeakerRanges(newspeakerRanges);
	}, [speakers, ranges]);

	const updateSpeakerRange = (speakerIndex: number, rangeIndex: number, newRange?: ThinRange) => {
		let seenOfIndex = 0;
		let index = 0;
		for (const range of ranges) {
			if (parseInt(range.speaker) === speakerIndex)
				seenOfIndex++;
			if (seenOfIndex === rangeIndex + 1) {
				updateRange(index, !!newRange ? { ...range, ...newRange } : undefined);
				return;
			}
			index++;
		}
	};

	return (
		<div className="speakersList">
			{speakers.map((s, i) =>
				<SpeakerItem
					key={i}
					index={i}
					name={s}
					setName={(newName) => changeSpeakerName(i, newName)}
					totalSecs={totalTimes[`${i}`]}
					savedRanges={speakerRanges[`${i}`] || []}
					updateRange={(rangeIndex, newRange) => updateSpeakerRange(i, rangeIndex, newRange)}
					addRange={(newRange) => addRange(i, newRange)}
					deleteSpeaker={() => deleteSpeaker(i)}
				/>
			)}
			<div className='addSpeakerButton'>
				<Button variant="outlined" onClick={() => addSpeaker()}>
					<Add style={{ color: '#d82b42' }} />
					<span>הוספת משתתף</span>
				</Button>
			</div>
		</div>
	);
};