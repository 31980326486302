import * as React from "react";
import { FC, useState } from 'react';
import "./TopBar.css";
import { Paper, Button, Menu, MenuItem, Tabs, Tab, Divider } from "@material-ui/core";
import { ExitToApp, SaveAlt, Unarchive, Add, Menu as MenuIcon } from "@material-ui/icons";
import { Rx } from "../../services/Rx";
import { useHistory } from "react-router-dom";
import { useSubscription } from "./useSubscription";
import WordsManager from "../../services/WordsManager";
import logo from '../../assets/logo.jpeg';
import Firebaser from "../../services/Firebaser";
import UrlReader from "../../services/UrlReader";
import SoundManager from "../../services/SoundManager";
import PizZip from 'pizzip';
// @ts-ignore
import "pizzip/dist/pizzip-utils";
// @ts-ignore
import Docxtemplater from "docxtemplater";
// @ts-ignore
import { saveAs } from "file-saver";
// @ts-ignore
import { FilePicker } from 'react-file-picker';
import TImeManager from "../../services/TImeManager";
import FileUploadManager from "../../services/FileUploadManager";
import whatsapp from '../../assets/whatsapp-icon.png';
import {jsonToSrt} from "../../services/CloudFunctionsService";
const docxTemplate = require("../../assets/templates/def.docx");
const docxTemplateLTR = require("../../assets/templates/defLTR.docx");
const docxTemplateWithTimes = require("../../assets/templates/defWithTimes.docx");
const docxTemplateWithTimesLTR = require("../../assets/templates/defWithTimesLTR.docx");

export const TopBar: FC = () => {
	const user = useSubscription(Rx.ConnectedUserName);
	const budget = useSubscription(Rx.Budget);
	const meeting = useSubscription(Rx.ChosenMeeting);
	const ltr = useSubscription(Rx.LTR);

	const history = useHistory();

	// React.useEffect(() => {
	// 	if (!UrlReader.isEditorHome() && !user)
	// 		history.push('/');
	// }, [history, user])

	const exportToWord = () => {
		if (!meeting || !meeting.words)
			return;

		const { ranges } = WordsManager.generateRanges(meeting);

		const transcript = ranges.map((rangeStart, i) => {
			const rangeEnd = i === ranges.length - 1 ? meeting.words.length : ranges[i + 1];
			const rangeWords = meeting.words.slice(rangeStart, rangeEnd).map(_ => _.text).filter(_ => !!_ && !!_.trim());

			return {
				speaker: WordsManager.getSpeakerName(meeting.words[rangeStart], meeting.metadata),
				"טקסט": rangeWords.join(" ")
			}
		}).filter(_ => !!(_["טקסט"] as string).trim());

		// @ts-ignore
		window['PizZipUtils'].getBinaryContent(ltr? docxTemplateLTR: docxTemplate,
			(error: any, content: any) => {
				if (error) { throw error };

				var zip = new PizZip(content);
				var doc = new Docxtemplater().loadZip(zip);

				doc.setData({
					name: meeting.metadata.name,
					date: TImeManager.getDateString(meeting.metadata.date),
					"משתתפים": Array.from(meeting.metadata.participants).join(', '),
					trans: transcript
				});

				doc.render()

				var out = doc.getZip().generate({
					type: "blob",
					mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				});
				saveAs(out, "סיכום פגישה.docx")
			})
	};

	const exportToWordWithTimes = () => {
		if (!meeting || !meeting.words)
			return;

		const { ranges } = WordsManager.generateRanges(meeting);

		const transcript = ranges.map((rangeStart, i) => {
			const rangeEnd = i === ranges.length - 1 ? meeting.words.length : ranges[i + 1];
			const rangeWords = meeting.words.slice(rangeStart, rangeEnd).map(_ => _.text).filter(_ => !!_ && !!_.trim());

			return ltr ? {
				speaker: WordsManager.getSpeakerName(meeting.words[rangeStart], meeting.metadata),
				time: TImeManager.getTimeStringFromSecs(meeting.words[rangeStart].start),
				"טקסט": rangeWords.join(" ")
			} : {
				speaker: WordsManager.getSpeakerName(meeting.words[rangeStart], meeting.metadata),
				"זמן": TImeManager.getTimeStringFromSecs(meeting.words[rangeStart].start),
				"טקסט": rangeWords.join(" ")
			}
		}).filter(_ => !!(_["טקסט"] as string).trim());

		// @ts-ignore
		window['PizZipUtils'].getBinaryContent(ltr ? docxTemplateWithTimesLTR : docxTemplateWithTimes,
			(error: any, content: any) => {
				if (error) { throw error };

				var zip = new PizZip(content);
				var doc = new Docxtemplater().loadZip(zip);

				doc.setData({
					name: meeting.metadata.name,
					date: TImeManager.getDateString(meeting.metadata.date),
					"משתתפים": Array.from(meeting.metadata.participants).join(', '),
					trans: transcript
				});

				doc.render()

				var out = doc.getZip().generate({
					type: "blob",
					mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				});
				saveAs(out, "סיכום פגישה.docx")
			})
	};

	const exportToSubtitles = async () => {
		Rx.LoadingReason.next('מבצע המרה לקובץ כתוביות');
		await WordsManager.saveChangedMeeting();

		const roomId = Rx.ChosenMeeting.value?.metadata.id;
		const meetingName = Rx.ChosenMeeting.value?.metadata.name;
		if (!roomId) {
			Rx.LoadingReason.next('');
			alert('תקלה במציאת פגישה לייצא');
			return;
		}

		await jsonToSrt(roomId, meetingName || roomId);
	};

	const handleFileChosen = (file: any) => {
		FileUploadManager.handleFileChosen(file, () => Rx.CurrentRecordingInd.next(Rx.Recordings.value.length - 1), true);
	}

	const backToHome = () => {
		if (!!Rx.LoadingReason.value)
			return;

		Rx.Recordings.next([]);
		Rx.ChosenMeeting.next(undefined);
		Rx.CurrentTime.next(0);
		SoundManager.pause();
		Rx.Query.next("");
		Rx.ChangesToSave.next(false);
		history.push("/");
	};

	const logout = () => {
		if (!!Rx.LoadingReason.value)
			return;

		Firebaser.auth.signOut();
		Rx.ConnectedUserName.next("");
		Rx.ConnectedUserId.next("");
		Rx.enteredPhoneNumber.next(false);
		Rx.Meetings.next([]);
		Rx.Batches.next([]);
		backToHome();
	};

	const recordings = useSubscription(Rx.Recordings);
	const currentRecordingInd = useSubscription(Rx.CurrentRecordingInd);

	const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
	const [recordingsAnchor, setRecordingsAnchor] = useState<null | HTMLElement>(null);

	const selectedLandingTab = useSubscription(Rx.LandingView);
	const selectedEditorHomeTab = useSubscription(Rx.EditorHomeView);
	const mediaTypes = useSubscription(Rx.MediaTypes);

	return (
		<Paper elevation={3} square id="topBar" >
			<div className="topBarItem forMobile" >
				{!!meeting && <Button className="actionButton" variant="outlined"
					// @ts-ignore
					onClick={(e) => setExportAnchor(e.currentTarget)}><Unarchive /><span>ייצוא</span></Button>}
				{UrlReader.isEditPage() && <Button className="actionButton" variant={"outlined"} onClick={backToHome}><ExitToApp /><span>מסך הבית</span></Button>}
				{!!meeting && <Button className="actionButton" variant={"outlined"} onClick={WordsManager.saveChangedMeeting}><SaveAlt /><span>שמירת מסמך</span></Button>}
			</div>
			<div className="topBarItem">
				{UrlReader.isEditorHome() && <>
					<Tabs value={selectedEditorHomeTab} onChange={(_, v) => Rx.EditorHomeView.next(v)}>
						<Tab value="myMeetings" label="פגישות לתמלול" />
						<Tab value="newMeeting" label="הקלטות ממתינות" />
					</Tabs>
					<Divider orientation="vertical" />
				</>}
				{!!user && (UrlReader.isEditorHome() || UrlReader.isEditPage()) && <div>
					<span>{`היי, ${user}`}</span>
					&nbsp;
					{
						// eslint-disable-next-line
						<a onClick={logout} style={{ cursor: "pointer", color: "blue" }}><u>התנתקות</u></a>
					}
					&nbsp;
					<span>{`(הבאדג'ט שלך ${Math.floor(budget * 100) / 100} דקות)`}</span>
				</div>}
				{UrlReader.isUploadPage() && <>
					<Button className="actionButton" variant={"outlined"} onClick={backToHome}><ExitToApp /><span>מסך הבית</span></Button>
					<FilePicker
						extensions={mediaTypes}
						maxSize={2000}
						onChange={handleFileChosen}
						onError={(err: string) => alert(err)} >
						<Button className="actionButton" variant={"outlined"} onClick={WordsManager.saveChangedMeeting}><Add /><span>הוספת הקלטה</span></Button>
					</FilePicker>
					<Button className="actionButton" variant={"outlined"} onClick={(e) => setRecordingsAnchor(e.currentTarget)}><MenuIcon /><span>ההקלטות שלי</span></Button>
				</>}
			</div>
			<img src={logo} alt="Sumit" className="topBarItem logoItem" />
			<div className="topBarItem" >
				{!!meeting && <Button className="actionButton" variant="outlined"
					// @ts-ignore
					onClick={(e) => setExportAnchor(e.currentTarget)}><Unarchive /><span>ייצוא</span></Button>}
				{UrlReader.isEditPage() && <Button className="actionButton" variant={"outlined"} onClick={backToHome}><ExitToApp /><span>מסך הבית</span></Button>}
				{!!meeting && <Button className="actionButton" variant={"outlined"} onClick={WordsManager.saveChangedMeeting}><SaveAlt /><span>שמירת מסמך</span></Button>}
			</div>

			<Menu
				open={!!exportAnchor}
				anchorEl={exportAnchor}
				className="exportMenu"
				keepMounted
				onClose={() => setExportAnchor(null)}
			>
				<MenuItem onClick={() => { setExportAnchor(null); exportToWord() }} >ייצא לקובץ docx</MenuItem>
				<MenuItem onClick={() => { setExportAnchor(null); exportToWordWithTimes() }} >ייצא לקובץ docx עם זמנים</MenuItem>
				<MenuItem onClick={() => { setExportAnchor(null); exportToSubtitles() }} >ייצא לקובץ srt</MenuItem>
			</Menu>

			<Menu
				open={!!recordingsAnchor}
				anchorEl={recordingsAnchor}
				className="exportMenu"
				keepMounted
				onClose={() => setRecordingsAnchor(null)}
			>
				{recordings.map((rec, i) =>
					<MenuItem key={i}
						onClick={() => { setRecordingsAnchor(null); Rx.CurrentRecordingInd.next(i) }}
						style={{ backgroundColor: currentRecordingInd === i ? "#FFBD1E" : "white" }}
					>{rec.name || 'ללא שם'}</MenuItem>
				)}
			</Menu>
		</Paper>
	);
}
