import { BehaviorSubject } from 'rxjs';
import { useEffect, useState } from 'react';

export const useSubscription = <T>(observable: BehaviorSubject<T>) => {
    const [value, setValue] = useState(observable && observable.value);

    useEffect(() => {
        if (!observable || !observable.subscribe) {
            return;
        }
        const subscription = observable.subscribe(setValue);
        return () => subscription.unsubscribe();
    }, [observable]);

    return value;
}