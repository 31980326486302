import * as React from "react";
import { FC, useCallback, useEffect, useState } from 'react';
import TImeManager from "../../services/TImeManager";
import "./MeetingItem.css";

interface Props {
	startDate: Date;
	endDate?: any;
}

const getDateDifference = (startDate: Date, endDate?: Date) => 
								Math.round(((endDate ?? new Date()).getTime() - startDate.getTime()) / 1000);

export const Countdown: FC<Props> = ({ startDate, endDate }) => {
	const [countdown, setCountdown] = useState(getDateDifference(startDate, endDate));

	const updateCountdown = useCallback(() => {
		setCountdown(getDateDifference(startDate, endDate));
	}, [startDate, endDate]);

	useEffect(() => {
		const interval = setInterval(() => {
			updateCountdown();
		}, 1000);
		return () => clearInterval(interval);
	}, [updateCountdown]);

	return <span className="cellItem meetingDate">{TImeManager.getTimeStringFromSecs(countdown)}</span>;
}
