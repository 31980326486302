import React, { FC, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Rx } from '../../services/Rx';
import { GroupAdd } from '@material-ui/icons';
import Firebaser from '../../services/Firebaser';
import { RecordingBatch } from '../../model/recordingBatch';
import { MeetingPreview } from '../../model/meetingPreview';

interface Props {
    meeting?: MeetingPreview;
    batch?: RecordingBatch;
    getMeetingsIds?: () => string[];
    disabled?: boolean;
}

export const AssignButton: FC<Props> = ({meeting, batch, getMeetingsIds, disabled}) => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const func = async () => setIsAdmin(await Firebaser.isAdmin(Rx.ConnectedUserId.value))
        func();
        Firebaser.getAllUsers();
    }, []);

    if(!isAdmin)
        return <></>;

    if(!!getMeetingsIds)
        return (
            <div style={{width:'15em'}}>
                <Button onClick={() => Rx.MeetingsToAssign.next(getMeetingsIds())}>
                    <GroupAdd style={{color:'gray'}}/>
                    <span style={{marginRight:'0.5em', color:'gray'}}>העבר הקלטות פנויות לטיפול</span>
                </Button>
            </div>
        );

    let meetingIds: string[];
    let assignedTo: string;

    if(!!batch){
        meetingIds = batch.recordingIds;
        assignedTo = batch.assignedTo;
    }
    else if(!meeting)
        return <></>;
    else {
        meetingIds = [meeting.id];
        assignedTo = meeting.assignedTo;
    }

    const color = disabled ? 'lightgray' : 'gray';

    return (
        <div className="assignButton" style={{width:'10em'}}>
            <Button onClick={() => {
                Rx.IsAssignTranscriberDialogOpen.next(true)
                Rx.MeetingsToAssign.next(meetingIds)
            }} disabled={disabled}>
                {!assignedTo ?
                    <><GroupAdd style={{color}} /><span style={{marginRight:'0.5em', color}}>העבר לטיפול</span></>
                    : <span style={{marginRight: '0.5em', color}}>בטיפול {Rx.TranscriberIdToName.value[assignedTo] ?? "לא מזוהה"}</span>
                }
            </Button>
        </div>
    );
}
