import config from "../config";
import axios from "axios";
import Firebaser from "./Firebaser";
import { Rx } from "./Rx";
// @ts-ignore
import { saveAs } from "file-saver";

const json2srtEndpoint = config.cloudFunctions.url + config.cloudFunctions.json2srt;

export const jsonToSrt = async (roomId: string, meetingName: string) => {
    const response = await axios.post(
        json2srtEndpoint,
        { roomId }
    );

    if (response.status !== 200) {
        throw new Error("json2srt - Failed to upload meeting");
    }

    const subtitlesUrl = await Firebaser.getSubtitlesUrl(roomId as string);

    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function (event) {
        const blob = xhr.response;
        saveAs(blob, `${meetingName || 'subtitles'}.srt`);
        Rx.LoadingReason.next('');
    };
    xhr.open('GET', subtitlesUrl);
    Rx.LoadingReason.next('המתן מבלי לסגור את החלון');
    xhr.send();
}
