import { Rx } from "./Rx";
import Firebaser from "./Firebaser";

class FileUploadManager {
    private static instance: FileUploadManager;

    private constructor() { }

    public static getInstance = () => {
        if (!FileUploadManager.instance)
            FileUploadManager.instance = new FileUploadManager();

        return FileUploadManager.instance;
    }

    public handleFileChosen = async (file: any, onSucess: () => void, shouldCheckBudget: boolean = true) => {
        if (!file)
            return;

        var tmpFilePath = URL.createObjectURL(file);

        if (shouldCheckBudget && Rx.Budget.value <= 0) {
            alert("המספר שלך לא מופיע אצלנו במערכת. \n כדי לצרף אותו יש לשלוח מייל לכתובת info@sumit-ai.com");
            return;
        }

        var audio = document.createElement('audio');
        Rx.LoadingReason.next('בודקים את קובץ השמע');

        audio.src = tmpFilePath as string;
        audio.addEventListener('loadedmetadata', async () => {
            Rx.LoadingReason.next('');

            const budget = Rx.Budget.value;
            const duration = Math.floor(audio.duration / 60);

            if (shouldCheckBudget && budget < duration) {
                alert(`הבאדג'ט שלך הוא ${budget} ואילו קובץ השמע שלך באורך ${duration} דקות\n כדי להגדיל אותו יש לשלוח מייל לכתובת info@sumit-ai.com`);
                return;
            }

            Rx.LoadingReason.next('מעלים את קובץ השמע שלך');
            await Firebaser.uploadAudioFile(file, audio.duration);
            Rx.LoadingReason.next('');
            Rx.ChosenMeeting.next(undefined);
            !!onSucess && onSucess();
        }, false);
    };
}

export default FileUploadManager.getInstance();