import * as React from "react";
import { FC, useState } from 'react';
import _ from 'lodash';
import { Meeting } from "../../model/meeting";
import IndexedListColorManager from "../../services/IndexedListColorManager";
import { Rx } from "../../services/Rx";
import "./MeetingHeader.css";
import TImeManager from "../../services/TImeManager";
import { Button, Dialog, DialogContent, DialogTitle, TextField, Tooltip, withStyles } from "@material-ui/core";
import WordsManager from "../../services/WordsManager";
import Firebaser from "../../services/Firebaser";
import { format } from 'date-fns';
import { stringify } from "querystring";

const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: '#f5f5f9',
	  color: 'rgba(0, 0, 0, 0.87)',
	  maxWidth: 220,
	  fontSize: theme.typography.pxToRem(12),
	  border: '1px solid #dadde9',
	},
  }))(Tooltip);

export const MeetingHeader: FC<{overlaps: number}> = ({overlaps}) => {
	const [meeting, setMeeting] = React.useState<Meeting>();
	const [working, setWorking] = useState(false);
	const [updatedAt, setUpdatedAt] = useState<string | null>(null);
	const [updatedBy, setUpdatedBy] = useState<string | null>(null);

	React.useEffect(() => {
		Rx.ChosenMeeting.subscribe(value => setMeeting(value));
	}, []);

	React.useEffect(() => {
		if (!meeting) return;

		if (!updatedAt && meeting.metadata.updatedAt) setUpdatedAt(format(meeting.metadata.updatedAt, 'dd/MM/yy | H:mm:ss'));
		if (!updatedBy && meeting.metadata.updatedBy) setUpdatedBy(Rx.AllUsers.value.find(u => u.id === meeting.metadata.updatedBy).username);
	}, [meeting]);

	const speakers = meeting?.metadata?.participants || new Set<string>();

	const [speakerToRename, setSpeakerToRename] = useState("");
	const [newName, setNewName] = useState("");

	const onClick = () => {
		if(newName === speakerToRename){
			setNewName('');
			setSpeakerToRename('');
			return;
		}

		if(speakers.has(newName)){
			alert('this name already exists for different speaker, choose another');
			setNewName('');
			return;
		}

		WordsManager.renameSpeaker(speakerToRename, newName);
		setNewName('');
		setSpeakerToRename('');
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
		}

		if(event.key === 'Escape')
			setSpeakerToRename('');
	};

	const setReadyDate = async (value?: boolean) => {
		if (!meeting) return;
		setWorking(true);
		Rx.SavingAlert.next(true);
		try {
			const currentReady = _.isEmpty(meeting.metadata.dateOfReady) ? false : meeting.metadata.dateOfReady && meeting.metadata.dateOfReady[0];
			const shouldSetReady = value || currentReady === false || currentReady && currentReady.value === false;

			const readyNewValue = [{
				value: shouldSetReady,
				uid: Rx.ConnectedUserName.value,
				timestamp: new Date()
			}, ...meeting.metadata.dateOfReady]

			await Firebaser.setReady(meeting.metadata.id, meeting.metadata.assignedTo, readyNewValue);
			meeting.metadata.dateOfReady = readyNewValue;
			Rx.ChosenMeeting.next({ ...meeting });
			Rx.SavingAlert.next(false);
			Rx.SavedAlert.next(true);
		}
		catch (err) {
			Rx.SavingFailed.next(true);
		} finally {
			setWorking(false);
		}
	}

	const setReadyToClient = async (meeting: Meeting) => {
		setWorking(true);
		Rx.SavingAlert.next(true);
		try {
			const newValue = !meeting?.metadata.deliveredToClient;
			// if (!newValue) {
			// 	await setReadyDate(false);
			// }

			const currentDelivered = _.isEmpty(meeting.metadata.deliveredToClient) ? false : meeting.metadata.deliveredToClient && meeting.metadata.deliveredToClient[0];
			const shouldSetDelivered = currentDelivered === false || currentDelivered && currentDelivered.value === false;

			const deliveredNewValue = [{
				value: shouldSetDelivered,
				uid: Rx.ConnectedUserName.value,
				timestamp: new Date()
			}, ...meeting.metadata.deliveredToClient]

			await Firebaser.setDelivered(meeting?.metadata.id, deliveredNewValue, meeting?.metadata.assignedTo);
			meeting.metadata.deliveredToClient = deliveredNewValue;
			Rx.Meetings.next([...Rx.Meetings.value]);
			Rx.ChosenMeeting.next({ ...meeting });
			Rx.SavingAlert.next(false);
			Rx.SavedAlert.next(true);
		} catch (err) {
			Rx.SavingFailed.next(true);
		} finally {
			setWorking(false);
		}
	}

	const TimestampTooltip = ({children, property}: {children: any, property: any}) => {
		if (!property || _.isEmpty(property) || !property[0].timestamp) return children;
		const dateFormat = 'dd/MM/yy | H:mm:ss';
		let time = '';
		if (property[0].timestamp.seconds) {
			time = format(property[0].timestamp.seconds * 1000, dateFormat);
		} else if (_.isDate(new Date(property[0].timestamp))) {
			time = format(property[0].timestamp, dateFormat);
		}
		
		return (property[0].timestamp || property[0].uid ? (
			<div>
				<HtmlTooltip title={property[0] ? (
						<div className="buttonTooltip rtl">
							<div className="date">
								{property[0].uid}
							</div>
							<div className="uid">
								{time}
							</div>
						</div>
					) : ""} placement="right" enterDelay={1000}>
						{children}
					</HtmlTooltip>
			</div>
		) : children);
	}

	if (!meeting)
		return <></>;

	return (
		<div className="rowFlex header">
			<div className="columnFlex">
				<div className="columnFlex">
					<div className="rowFlex">
						<span className="meetingNameHeader">שם הפגישה: &nbsp;<b>{meeting.metadata.name}</b></span>
						{meeting.metadata.price ? <span className="meetingPriceHeader">תשלום: &nbsp;<b>{meeting.metadata.price}₪</b></span> : null}
						{meeting.metadata.knowledge ? <span className="meetingKnowledge">תחומי דעת: &nbsp;<b>{meeting.metadata.knowledge}</b></span> : null}
					</div>
					<div className="rowFlex">
						<span className="uploadMeetingDateHeader">תאריך:&nbsp;<b>{TImeManager.getDateString(meeting.metadata.date)}</b></span>
						<span className="ownerNameHeader">שם מעלה ההקלטה: &nbsp;<b>{meeting.metadata.ownerName}</b></span>
						<span className="ownerPhoneHeader">טלפון: &nbsp;<b>{meeting.metadata.ownerPhone}</b></span>
						<span className="ownerMailHeader">מייל: &nbsp;<b>{meeting.metadata.ownerMail}</b></span>
					</div>
					<div className="rowFlex">
						<span className="lastSaved">נשמר לאחרונה:</span>
						<span className="lastSavedDate"><b>{updatedAt}</b></span>
						<span className="lastSavedUser"><b>{updatedBy}</b></span>
					</div>
					<div className="rowFlex">
						{meeting.metadata.isSubtitles ? <span className="ownerNameHeader">שגיאות: <b>{overlaps}</b></span> : null}
					</div>

					{!meeting.metadata.isSubtitles ? (
						<div className="rowFlex">
							<span className="meetingParticipantsLabel">משתתפים:</span>
							<div className="meetingParticipants">
								{Array.from(speakers).map((person, i) =>
									<div key={i}>
										{i > 0 && <span className="participantHeader">|</span>}
										<b><a
											className="participantHeader"
											onClick={() => setSpeakerToRename(person)}
											style={{ color: IndexedListColorManager.getColorByIndex(i) }} key={i}>
											{person}
										</a></b>
									</div>)}
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className="columnFlex">
				<div className="rowFlex">
				<TimestampTooltip property={meeting?.metadata.dateOfReady}>
					<div>
						<Button className="readyButton"
									onClick={() => setReadyDate()}
									style={{
										backgroundColor: meeting?.metadata.dateOfReady[0] && meeting?.metadata.dateOfReady[0].value ? "green" : "red",
										color: "white",
										marginLeft: '1em',
										width: '120px',
										opacity: working || overlaps > 0 ? .3 : 1,
									}}
									disabled={working || overlaps > 0}
									>מוכן לביקורת</Button>
						</div>
				</TimestampTooltip>
				</div>
				{Firebaser.admin && 
					<div className="rowFlex">
						<TimestampTooltip property={meeting?.metadata.deliveredToClient}>
							<div>
								<Button className="deliveredToClient"
											onClick={() => setReadyToClient(meeting)}
											style={{
												backgroundColor: meeting?.metadata.deliveredToClient[0] && meeting?.metadata.deliveredToClient[0].value ? "green" : "gray",
												color: "white",
												marginLeft: '1em',
												width: '120px',
												opacity: working || overlaps > 0 || !meeting?.metadata.dateOfReady ? .3 : 1,
											}}
											disabled={working || overlaps > 0 || !meeting?.metadata.dateOfReady}
									>נשלח ללקוח</Button>
							</div>
						</TimestampTooltip>
					</div>
				}
			</div>

			<Dialog open={!!speakerToRename} onClose={() => setSpeakerToRename('')} onKeyDown={onKeyDown}>
				<DialogTitle>שנה שם מ"{speakerToRename}" ל:</DialogTitle>
				<DialogContent className='renameDialogContent'>
					<TextField value={newName}
							   onChange={e => setNewName(e.target.value as string)}
							   placeholder='הכנס שם חלופי'
							   autoFocus
							   variant='outlined' />
					<Button onClick={onClick} disabled={!newName}>שנה</Button>
				</DialogContent>
			</Dialog>
		</div>
	);
}
