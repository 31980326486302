import { Meeting } from "../model/meeting";
import { BehaviorSubject } from 'rxjs';
import { Recording } from "../model/recording";
import { RecordingBatch } from "../model/recordingBatch";
import { MeetingPreview } from "../model/meetingPreview";

class SessionSubject<T> extends BehaviorSubject<T>{
    constructor(key: string, initialValue: T) {
        const sessionStringValue = sessionStorage.getItem(key);
        let sessionValue: T;
        if (!sessionStringValue)
            sessionValue = initialValue;
        else
            sessionValue = JSON.parse(sessionStringValue);

        super(sessionValue);
        super.subscribe(newValue => sessionStorage.setItem(key, JSON.stringify(newValue)));
    }
}

class RxAppStore {
    private static instance: RxAppStore;

    public IsAuthDialogOpen = new BehaviorSubject<boolean>(false);
    public IsPaymentDialogOpen = new BehaviorSubject<boolean>(false);
    public IsAssignTranscriberDialogOpen = new BehaviorSubject<boolean>(false);
    public IsAddTranscriberDialogOpen = new BehaviorSubject<boolean>(false);
    public HasCostumerUploaded = new BehaviorSubject<boolean>(false);
    public UploadProgress = new BehaviorSubject<number>(-1);

    public AllUsers = new BehaviorSubject<any[]>([]);
    public ConnectedUserName = new SessionSubject<string>("userName", "");
    public ConnectedUserMail = new SessionSubject<string>("userMail", "");
    public ConnectedUserId = new SessionSubject<string>("userId", "");
    public enteredPhoneNumber = new BehaviorSubject<boolean>(false);
    public Budget = new BehaviorSubject<number>(0);

    public TranscriberIdToName = new BehaviorSubject<{[id: string]: string}>({});
    public MediaTypes = new BehaviorSubject<string[]>([]);

    public Meetings = new BehaviorSubject<MeetingPreview[]>([]);
    public FilteredMeetings = new BehaviorSubject<MeetingPreview[]>([]);
    public Batches = new BehaviorSubject<RecordingBatch[]>([]);
    public LoadingReason = new BehaviorSubject<string>("");
    public AssigningMeetingAlert = new BehaviorSubject<boolean>(false);
    public SavedAlert = new BehaviorSubject<boolean>(false);
    public SavingAlert = new BehaviorSubject<boolean>(false);
    public SavingFailed = new BehaviorSubject<boolean>(false);
    public IsDoneUploading = new BehaviorSubject<boolean>(false);

    public MeetingsToAssign = new BehaviorSubject<string[]>([]);
    public MeetingsToDelete = new BehaviorSubject<string[]>([]);

    public Recordings = new BehaviorSubject<Recording[]>([]);
    public CurrentRecordingInd = new BehaviorSubject<number>(0);

    public ChosenMeeting = new BehaviorSubject<Meeting | undefined>(undefined);
    public ChangesToSave = new BehaviorSubject<boolean>(false);
    public SelectedWordInd = new BehaviorSubject<number[]>([]);
    public Query = new BehaviorSubject<string>("");
    public LTR = new BehaviorSubject<boolean>(false);

    public isSubtitles = new BehaviorSubject<boolean>(false);
    public IsPlaying = new BehaviorSubject<boolean>(false);
    public CurrentTime = new BehaviorSubject<number>(0);

    public LandingView = new BehaviorSubject<"information" | "calculator">("calculator");
    public EditorHomeView = new BehaviorSubject<"myMeetings" | "newMeeting">("myMeetings");

    private constructor() { }

    public static getInstance = () => {
        if (!RxAppStore.instance)
            RxAppStore.instance = new RxAppStore();

        return RxAppStore.instance;
    }
}

export const Rx = RxAppStore.getInstance();
