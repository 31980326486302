import _ from "lodash";
import { Rx } from "./Rx";
import Firebaser from "./Firebaser";
import { Word } from "../model/word";
import { MeetingMetadata } from "../model/meetingMetadata";
import { Meeting } from "../model/meeting";

class WordsManager {
    private static instance: WordsManager;

    private constructor() { }

    public static getInstance = () => {
        if (!WordsManager.instance)
            WordsManager.instance = new WordsManager();

        return WordsManager.instance;
    }

    public getSpeakerName = (word: Word, metadata: MeetingMetadata) => {
        if (metadata.participants.has(word.speaker))
            return word.speaker;
        return metadata.identifiedParticipants[word.speaker] || word.speaker;
    };

    public saveChangedMeeting = async () => {
        // if (!Rx.ChangesToSave.value)
        //     return;

        Rx.SavingAlert.next(true);
        Rx.SavingFailed.next(false)

        await Firebaser.updateMeeting();

        Rx.SavingAlert.next(false);
        Rx.ChangesToSave.next(false);
    };

    public matchToQuery = (word: string, query: string) => {
        if (!query || !word)
            return false;

        const seperatedWord = word.split(' ').filter(_ => !!_);
        const seperatedQuery = query.split(' ').filter(_ => !!_);

        return seperatedWord.some(w => seperatedQuery.some(q => w.includes(q)));
    }

    public changeSpeaker = (speaker: string) => {
        const updatedMeeting = Rx.ChosenMeeting.value;
        if (!updatedMeeting)
            return;

        if (!updatedMeeting.metadata.participants.has(speaker))
            updatedMeeting.metadata.participants.add(speaker);

        Rx.SelectedWordInd.value.forEach((i) => {
            updatedMeeting.words[i].speaker = speaker;
        });

        Rx.ChosenMeeting.next({ ...updatedMeeting });
        Rx.ChangesToSave.next(true);
    }

    public renameSpeaker = (oldSpeaker: string, newSpeaker: string) => {
        const updatedMeeting = Rx.ChosenMeeting.value;
        if (!updatedMeeting)
            return;

        updatedMeeting.words.filter(word => this.getSpeakerName(word, updatedMeeting.metadata) === oldSpeaker).forEach(word => {
            word.speaker = newSpeaker;
        });

        updatedMeeting.metadata.participants.delete(oldSpeaker);
        updatedMeeting.metadata.participants.add(newSpeaker);

        const identifiedSpeakerIndex = Object.values(updatedMeeting.metadata.identifiedParticipants).indexOf(oldSpeaker);
        if(identifiedSpeakerIndex > -1) {
            updatedMeeting.metadata.renamedIdentifiedParticipandsIds.push(
                Object.keys(updatedMeeting.metadata.identifiedParticipants)[identifiedSpeakerIndex]
            );
        }

        Rx.ChosenMeeting.next({ ...updatedMeeting });
        Rx.ChangesToSave.next(true);
    }

    public chooseAlter = (alternative: string, index: number) => {
        const updatedMeeting = Rx.ChosenMeeting.value;
        if (!updatedMeeting)
            return;

        updatedMeeting.words[index].text = alternative;
        Rx.ChosenMeeting.next({ ...updatedMeeting });
    }


    private copiedText: string = "";

    public Copy = (text: string) => {
        this.copiedText = text;
    };

    public Cut = (text: string) => {
        this.Copy(text);

        const meeting = Rx.ChosenMeeting.value;
        if (!meeting)
            return;

        Rx.SelectedWordInd.value.forEach((i) => {
            meeting.words[i].text = "";
        });

        Rx.ChosenMeeting.next({ ...meeting });
    };

    public Paste = (wordIndex: number, wordText: string, offset: number) => {
        const meeting = Rx.ChosenMeeting.value;
        if (!meeting)
            return;

        meeting.words[wordIndex].text = wordText.slice(0, offset) + this.copiedText + wordText.slice(offset);

        Rx.ChosenMeeting.next({ ...meeting });
    };


    public Bold = () => this.toggleStyle("bold");

    public Mark = () => this.toggleStyle("marked");

    public Underline = () => this.toggleStyle("underline");

    private toggleStyle = (styleProperty: "marked" | "bold" | "underline") => {
        const meeting = Rx.ChosenMeeting.value;
        if (!meeting)
            return;

        Rx.SelectedWordInd.value.forEach((i) => {
            meeting.words[i].style[styleProperty] = !meeting.words[i].style[styleProperty];
        });

        Rx.ChosenMeeting.next({ ...meeting });
    }

    public FontLarger = () => this.FontChangeBy(1);

    public FontSmaller = () => this.FontChangeBy(-1);

    private FontChangeBy = (fontChange: number) => {
        const meeting = Rx.ChosenMeeting.value;
        if (!meeting)
            return;

        Rx.SelectedWordInd.value.forEach((i) => {
            meeting.words[i].style.fontSize = meeting.words[i].style.fontSize + fontChange;
        });

        Rx.ChosenMeeting.next({ ...meeting });
    };

    public ChangeFont = (font: 'david' | 'gils') => {
        const meeting = Rx.ChosenMeeting.value;
        if (!meeting)
            return;

        Rx.SelectedWordInd.value.forEach((i) => {
            meeting.words[i].style.font = font;
        });

        Rx.ChosenMeeting.next({ ...meeting });
    };

    public validateOverlaps = (meeting: Meeting, ranges: number[]) => {
        const words = meeting.words;
        const overlaps: number[] = [];

        for (let i = 0; i < ranges.length; i++) {
            const range = ranges[i];
            const isLastRange = i === ranges.length-1;
            const rangeStart = words[range].start;
            const rangeEnd = isLastRange ? words[words.length-1].end : words[ranges[i+1]-1].end;
            const nextRangeStart = !isLastRange ? words[ranges[i+1]].start : null;

            const isSingleRangeOverlap = rangeStart >= rangeEnd;
            const isTwoRangeOverlap = !_.isNil(nextRangeStart) ? rangeEnd > nextRangeStart : false;

            if (isTwoRangeOverlap) {
                overlaps.push(i);
                overlaps.push(i+1);
                continue;
            }

            if (isSingleRangeOverlap) {
                overlaps.push(i);
            }
        }

        return overlaps;
    }

    public generateRanges = (meeting: Meeting) => {
        const ranges: number[] = [];
        const timeRanges: {rangeIndex: number, start: number, end: number, overlapping: boolean}[] = [];
        const currentRange: number = 0;

        if (meeting) {
            if (meeting.metadata.isSubtitles && !_.isNil(meeting.words[0].range_ix)) {
                let currentRange: number = -1;
                let rangeInstance: { rangeIndex?: number, start?: number, end?: number, overlapping: boolean } | null = null;
                let timeRangeStart = meeting.words[0].start;

                for (let i = 0; i < meeting.words.length; i++) {
                    const word = meeting.words[i];

                    if (i === meeting.words.length-1) {
                        const isOverlapping = i !== meeting.words.length-1 && meeting.words[i-1].end < word.start;
                        const lastRange = {
                            rangeIndex: timeRanges.length,
                            start: timeRangeStart,
                            end: meeting.words[meeting.words.length-1].end,
                            overlapping: isOverlapping
                        }
                        timeRanges.push(!_.isNil(rangeInstance) ? {...rangeInstance, ...lastRange} : lastRange);
                        rangeInstance = null;
                    }

                    if (_.isNil(word.range_ix) || word.range_ix === currentRange || !word.text)
                        continue;

                    currentRange = word.range_ix;
                    ranges.push(i);

                    if (i !== 0) {
                        const isOverlapping = meeting.words[i-1].end > word.start;
                        timeRanges.push({
                            rangeIndex: timeRanges.length,
                            start: timeRangeStart,
                            end: meeting.words[i-1].end,
                            overlapping: isOverlapping,
                            ...rangeInstance
                        })
                        if (isOverlapping) {
                            rangeInstance = {
                                overlapping: isOverlapping
                            }
                        } else {
                            rangeInstance = null;
                        }
                    }
                    timeRangeStart = word.start
                }
            } else {
                let currentSpeaker: string = "";

                for (let i = 0; i < meeting.words.length; i++) {
                    const word = meeting.words[i];

                    if (!word.speaker || word.speaker === currentSpeaker || !word.text)
                        continue;

                    currentSpeaker = word.speaker;
                    ranges.push(i);
                }
            }
        }

        return {
            ranges,
            timeRanges,
            currentRange
        };
    };

    public saveLog = (newWords: string[], oldWords: string[], recordingTime: string) => {
        Firebaser.writeLog(newWords, oldWords, recordingTime);
    };
}

export default WordsManager.getInstance();
