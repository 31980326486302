import { Range } from "../components/EditorPage/ColoredSeekBar";

export class Recording {
	meetingId: string;

	name: string = "";
	date: Date = new Date();

	audio: string;
	length: number;

	speakers: string[] = [];
	ranges: Range[] = [];

	constructor(meetingId: string, audio: string, length: number) {
		this.meetingId = meetingId;
		this.audio = audio;
		this.length = length;
	}
}