import * as React from "react";
import { FC } from 'react';
import { Dialog, TextField, Button, DialogContent } from '@material-ui/core';
import './ContactDetailInputDialog.css'


interface Props {
	open: boolean;
	name: string;
	mail: string;
	onInput: (name: string, mail: string) => void;
	onClose?: () => void;
}

export const ContactDetailInputDialog: FC<Props> = ({ open, onInput, name = "", mail = "", onClose }) => {
	const [nameInput, setNameInput] = React.useState(name);
	const [mailInput, setMailInput] = React.useState(mail);
	const [isInvalidMail, setIsInvalidMail] = React.useState(false);
	const [disabled, setDisabled] = React.useState(true);

	React.useEffect(() => {
		setNameInput(name);
		setMailInput(mail);
		setDisabled(false);
	}, [open, name, mail, setNameInput, setMailInput, setDisabled]);

	const sendInput = () => {
		onInput(nameInput, mailInput);
		setDisabled(true);
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			sendInput();
		}
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogContent className="inputDialog">
				<span className="conactDescriptio">הזן פרטי משתמש</span>
				<div className='withLabel'>
					<span>שם מלא</span>
					<TextField
						disabled={!!name}
						value={nameInput}
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						placeholder={"איך קוראים לך?"}
						onChange={(e) => setNameInput(e.target.value)}
						variant="outlined"
						autoFocus
						onKeyDown={onKeyDown} />
				</div>
				<div className='withLabel'>
					<span>כתובת מייל</span>
					<TextField
						disabled={!!mail}
						value={mailInput}
						inputProps={{ min: 0, style: { textAlign: 'center' } }}
						placeholder={"מה המייל שלך?"}
						onChange={(e) => {
							const mail = e.target.value;
							setIsInvalidMail(!/\S+@\S+\.\S+/.test(mail));
							setMailInput(e.target.value)
						}}
						variant="outlined"
						autoFocus
						error={isInvalidMail}
						helperText={isInvalidMail && 'כתובת מייל אינה תקינה'}
						onKeyDown={onKeyDown} />
				</div>
				<Button disabled={disabled || isInvalidMail}
					onClick={() => sendInput()}
					variant={"outlined"}
					id="dialogButton">שלח</Button>
			</DialogContent>
		</Dialog>
	);
}
