import _ from "lodash";
import config from "../config";
import { version } from "../../package.json";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { MediaSource, Meeting } from "../model/meeting";
import { Rx } from "./Rx";
import { MeetingMetadata } from "../model/meetingMetadata";
import { Word } from "../model/word";
import { RangesInfo } from "../model/rangesInfo";
import { Recording } from "../model/recording";
import { RecordingBatch } from "../model/recordingBatch";
import { Range } from "../components/EditorPage/ColoredSeekBar";
import IndexedListColorManager from "./IndexedListColorManager";
import { RecieptData } from "../model/recieptData";
import { MeetingPreview } from "../model/meetingPreview";

const axios = require('axios');

const FFgroupId = false;

class Firebaser {
    private static instance: Firebaser;
    public isProject7 = window.location.hostname.startsWith("project7");
    private roomsCollection = this.isProject7 ? "rooms7000" : "rooms";
    private jobsCollection = this.isProject7 ? "jobs7000" : "jobs";

    public db: firebase.firestore.Firestore;
    public storage: firebase.storage.Storage;
    public auth: firebase.auth.Auth;

    public verifier: firebase.auth.RecaptchaVerifier;

    public admin: boolean;

    private constructor() {
        const fb = firebase.initializeApp(config.firebase);
        this.auth = fb.auth();
        this.db = fb.firestore();
        this.storage = firebase.storage();
        this.admin = false;

        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        this.auth.onAuthStateChanged(async user => {
            if (!!user) {
                await this.getUserNameAndMail(user.uid, user.phoneNumber as string);
                await this.getUserBudget(user.uid);
                this.admin = await this.isAdmin(user.uid);
                Rx.ConnectedUserId.next(user.uid);
            }
        })
    }

    public static getInstance = () => {
        if (!Firebaser.instance)
            Firebaser.instance = new Firebaser();

        return Firebaser.instance;
    }

    private updateDocument = async (collection: string, id: string, data: object) => {
        if (!collection || !id || !data) return null

        const userId = Rx.ConnectedUserId.value;

        const documentRef = this.db.doc(`${collection}/${id}`)
        const updatedAt = Date.now()
        const updatedBy = userId || "nouserid"

        const updates = {...data, updatedAt, updatedBy}

        await documentRef.update(updates)

        return updates
    }

    private getAllRecordingsQuery = () => this.db.collection(this.roomsCollection).where('status', 'in', [1, 2, 2.5]).where('wasPaid', '==', true);
    private getAssignedRecordingsQuery = (userId: string) => this.getAllRecordingsQuery().where('assigned_id', '==', userId);

    private getAllMeetingsQuery = () => this.db.collection(this.roomsCollection).where('status', '==', 3);
    private getOwnedMeetings = (userId: string) => this.getAllMeetingsQuery().where('owner_id', '==', userId).get();
    private getAssignedMeetings = (userId: string) => this.getAllMeetingsQuery().where('assigned_id', '==', userId).get();
    private getProoferMeetings = () => this.db.collection(this.roomsCollection).where('ready_date', '!=', null).get();

    private getUserMeetingsDocs = async (userId: string) => {
        const userDoc = await this.db.collection('users').doc(userId).get();
        const user = await userDoc.data();

        //@ts-ignore
        if (user.proofer) {
            const prooferMeetings = await this.getProoferMeetings();
            return prooferMeetings.docs.filter(meeting => !meeting.data().delivered_to_client);
        }

        const owned = await this.getOwnedMeetings(userId);
        const assigned = await this.getAssignedMeetings(userId);

        if(!owned || !assigned)
            return null;

        if(owned.empty && assigned.empty)
            return [];

        if(owned.empty)
            return assigned.docs;

        if(assigned.empty)
            return owned.docs;

        const combined = [...owned.docs];
        assigned.docs.forEach(doc => {
            if(!combined.find(_ => _.id === doc.id))
                combined.push(doc)
        });

        return combined;
    }

    private getAllMeetingDocs = async () => {
        const all = await this.getAllMeetingsQuery().get();

        if(!all)
            return null;

        if(all.empty)
            return [];

        return all.docs;
    }

    public getMeetingMetadata = async (meetingId: string) => {
        const doc = await this.db.collection(this.roomsCollection).doc(meetingId).get();
        if(!doc)
            throw new Error("noDoc");

        const data = doc.data();

        if (!data?.creation_time)
            throw new Error("noCreationTime");

        const date = new Date(data.creation_time.seconds * 1000);

        const membersQuerySnapshot = await this.db.collection(this.roomsCollection).doc(doc.id).collection("members").get();
        const membersData = membersQuerySnapshot.docs.map((d: any) => d.data());

        const participants = new Set<string>(data.additionalMemebers || []);
        const identifiedParticipants: {[id: string]: string} = {};
        membersData.forEach(m => {
            if(!data.renamedIdentifiedParticipandsIds || !data.renamedIdentifiedParticipandsIds.includes(m.id))
                participants.add(m.name)
            identifiedParticipants[m.id] = m.name;
        });

        let meetingLengthInSec = data.meetingLength;
        if (!meetingLengthInSec) {
            const ownerId: string = data.owner_id.id;
            const ownerLeavingTime = membersData.filter(m => m.id === ownerId)[0]?.leavingTime;

            if (!ownerLeavingTime || !data.record_beginning_date)
                throw new Error(`noOwnerLeavingTime || record_beginning_date ${ownerLeavingTime} ${data}`);

            meetingLengthInSec = ownerLeavingTime - data.record_beginning_date;
        }

        const ownerId = typeof(data.owner_id) == typeof("a") ? data.owner_id : data.owner_id.id;

        const ownerDoc = await this.db.collection('users').doc(ownerId).get();
        const ownerData = ownerDoc.data();
        const ownerName = ownerData?.username || 'לא מזוהה';
        const ownerPhone = ownerData?.phonenumber || 'לא מזוהה';
        const ownerMail = ownerData?.mail || 'לא מזוהה';
        const price = data?.price || null;
        const knowledge = data?.knowledge || null;
        const assignedTo = data?.assigned_id;
        const dateOfReady = data?.ready_date ? _.isArray(data.ready_date) ? data.ready_date : [{value: true, timestamp: data.ready_date}] : [];
        const deliveredToClient = data?.delivered_to_client ? _.isArray(data.delivered_to_client) ? data.delivered_to_client : [{value: true, timestamp: null}] : [];

        return {
            id: doc.id,
            name: data.name,
            date,
            participants,
            identifiedParticipants,
            renamedIdentifiedParticipandsIds: [],
            lengthInSecs: meetingLengthInSec,
            ownerName,
            ownerMail,
            ownerPhone,
            price,
            knowledge,
            isSubtitles: data.isSubtitles || false,
            dateOfReady,
            deliveredToClient,
            assignedTo,
            updatedAt: data.updatedAt || null,
            updatedBy: data.updatedBy || null
        } as MeetingMetadata;
    };

    public getUserMeetings = async (userId: string) => {
        var ret: MeetingPreview[] = [];

        const userMeetingsDocs = await this.isAdmin(userId) ? await this.getAllMeetingDocs() : await this.getUserMeetingsDocs(userId);

        if (!userMeetingsDocs) {
            console.log("תקלה בשליפת מסמך");
            return [];
        }

        for (let doc of userMeetingsDocs) {
            const data = doc.data();

            if (!data.creation_time) {
                continue;
            }

            const date = new Date(data.creation_time.seconds * 1000);
            const dateOfReady = data?.ready_date ? _.isArray(data.ready_date) ? data.ready_date[0].value ? new Date(data.ready_date[0].timestamp.seconds * 1000) : undefined : new Date(data.ready_date.seconds * 1000) : undefined;
            const deliveredToClient = data?.delivered_to_client ? _.isArray(data.delivered_to_client) ? data.delivered_to_client[0].value ? new Date(data.delivered_to_client[0].timestamp.seconds * 1000) : undefined : new Date(data.delivered_to_client.seconds * 1000) : undefined;
    

            let folderPath: {[uid: string]: string} = {};
            const dataFolderPath = data.folder_path;

            if (!dataFolderPath)
                folderPath[userId] = '';
            else if (typeof dataFolderPath === 'string' || dataFolderPath instanceof String)
                folderPath[userId] = dataFolderPath as string;
            else
                folderPath = dataFolderPath;

            if (!folderPath[userId] && !folderPath.PUBLIC)
                folderPath[userId] = '';

            ret.push({
                id: doc.id,
                name: data.name,
                date,
                dateOfReady,
                folderPath,
                assignedTo: data.assigned_id as string,
                deliveredToClient,
                lengthInSecs: data.meetingLength
            });
        }
        Rx.Meetings.next(ret);
    }

    public getWaitingRecordingBatches = async (userId: string) => {
        const query = await this.isAdmin(userId) ? this.getAllRecordingsQuery() : this.getAssignedRecordingsQuery(userId)
        const waitingRooms = await query.get();

        if (!waitingRooms) {
            return [];
        }

        if (waitingRooms.empty)
            return [];

        const groupDict: { [id: string]: RecordingBatch } = {};
        if (FFgroupId) {
            for (let doc of waitingRooms.docs) {
                const roomData = doc.data();

                // const groupId: string = roomData.group_id as string;
                // after requested feature of splitting to diferent lines in waiting recordings
                const groupId: string = doc.id as string;
                if (!groupId || this.isProject7)
                    continue;

                const ownerId = typeof (roomData.owner_id) == typeof ("a") ? roomData.owner_id : roomData.owner_id.id;

                const userDoc = await this.db.collection('users').doc(ownerId).get();
                const userData = userDoc.data();
                let username = !!userData ? userData.username : 'לא מזוהה';

                if (!groupDict[groupId])
                    groupDict[groupId] = {
                        user: username,
                        name: roomData.name,
                        uploadDate: new Date(roomData.creation_time.seconds * 1000),
                        recordingIds: [],
                        assignedTo: roomData.assigned_id as string,
                        algorithm: roomData.algorithm,
                        inProcessing: roomData.status !== 2
                    };

                groupDict[groupId].recordingIds.push(doc.id);
            }
        }
        return Rx.Batches.next(Object.values(groupDict));
    };

    public updateMeeting = async () => {
        try {
            const storageRef = await firebase.storage();
            await storageRef.setMaxUploadRetryTime(0);
            const meeting = Rx.ChosenMeeting.value;
            if (!meeting) {
                alert('לא נמצאה פגישה לעדכן');
                return;
            }
            const validatedWords = await this.validateSubtitlesRanges(meeting.words);
            const blob = new Blob([JSON.stringify(validatedWords)], { type: "application/json" });

            const updateFileRef = storageRef.ref(`rooms/${meeting.metadata.id}/ready.json`);
            await updateFileRef.put(blob);

            const newRevisionRef = storageRef.ref(`rooms/${meeting.metadata.id}/revisions/revision_${Date.now()}.json`)
            await newRevisionRef.put(blob);

            console.log('Uploaded a blob!');

            await this.updateDocument(this.roomsCollection, meeting.metadata.id,
                {
                    additionalMemebers: Array.from(meeting.metadata.participants),
                    renamedIdentifiedParticipandsIds: Array.from(meeting.metadata.renamedIdentifiedParticipandsIds),
                    isSubtitles: meeting.metadata.isSubtitles
                }
            );
            console.log('Updated metadata!');
            Rx.SavedAlert.next(true);
        } catch (err) {
            console.log("Update meeting failed")
            console.log(err)
            Rx.SavingFailed.next(true);
        }
    };

    private ID = () => {
        return '_' + Math.random().toString(36).substr(2, 9);
    };

    public getMeeting = async (meetingId: string) => {
        Rx.LoadingReason.next("טוענים את כל מה שצריך בשבילך");

        const metadata = await this.getMeetingMetadata(meetingId);
        if(!metadata) {
            alert('שגיאה בקבלת מידע הנלווה לפגישה');
            throw new Error("getMeeting failed")
        }

        const { audioSources, videoSources } = await this.getMeetingMediaFiles(meetingId);

        const wordsUrl: string = await this.storage.ref(`rooms/${meetingId}/ready.json`).getDownloadURL();

        const response = await axios.get(wordsUrl);
        if (response.status !== 200) {
            alert('שגיאה בקבלת מסמך התמלול');
            throw new Error("getMeeting failed")
        }

        const words = (response.data).map((_: any, index: number) => {
            _.text = _.text || _.word;
            _.start = _.start || _.start_time;
            _.end = _.end || _.end_time;
            _.id = _.id || this.ID();
            _.style = _.style || { bold: false, marked: false, underline: false, font: 'gils', fontSize: 16 };
            _.style.fontSize = _.style.fontSize || 13;
            _.style.font = _.style.font || 'gils';
            return _ as Word;
        }).filter((_: any) => !!_.text);

        Rx.ChosenMeeting.next({
            metadata,
            words,
            audioSources,
            videoSources
        });
        Rx.LoadingReason.next("");
    };

    public syncBatchDataToRecordings = async (roomIds: string[]) => {
        const recordings: Recording[] = [];
        for (const id of roomIds) {
            const audioRef = (await this.storage.ref(`rooms/${id}`).listAll())
                .items
                .filter(item => (item.name.startsWith('raw.') && !!Rx.MediaTypes.value.some(suffix => item.name.endsWith(suffix))));

            if (audioRef.length === 0)
                continue;
            const audioUrl = await audioRef[0].getDownloadURL();

            const metadataSnapshot = await this.db.collection(this.roomsCollection).doc(id).get();
            if (!metadataSnapshot)
                continue;

            const metadata = metadataSnapshot.data();
            if (!metadata)
                continue;

            const speakers: string[] = metadata.additionalMemebers;

            let slices: { [speakerId: string]: number[][] } = {};
            try{
                const slicesUrl: string = await this.storage.ref(`rooms/${id}/slices.txt`).getDownloadURL();
                const response = await axios.get(slicesUrl);
                if (response.status !== 200)
                    continue;

                slices = (response.data) as { [speakerId: string]: number[][] };
            }
            catch {
                console.warn("slices file couldnt be loaded")
            }

            const ranges: Range[] = Object.keys(slices).flatMap(
                (speakerId: string) => slices[speakerId].map(
                    (slice: number[]): Range => ({
                        speaker: `${speakers.indexOf(speakerId)}`,
                        start: slice[0],
                        end: slice[1],
                        color: IndexedListColorManager.getColorByIndex(speakers.indexOf(speakerId))
                    })
                )
            ).sort((a, b) => a.start - b.start);

            recordings.push({
                meetingId: id,
                audio: audioUrl,
                length: metadata.meetingLength,
                date: new Date(metadata.creation_time.seconds * 1000),
                name: metadata.name,
                speakers: metadata.additionalMemebers,
                ranges: ranges
            })
        }

        Rx.Recordings.next(recordings);
    };

    public getUserBudget = async (userId: string) => {
        Rx.Budget.next(0);

        const doc = await this.db.collection('users')
            .doc(userId).get();

        const data = doc.data();
        if (!data) {
            alert("לא נמצא משתמש");
            return;
        }
        Rx.Budget.next(data.budget);
    };

    public getMeetingMediaFiles = async (meetingId: string): Promise<{ audioSources: MediaSource[], videoSources: MediaSource[] }> => {
        let offsets: { [id: string]: number } = {};

        try {
            const offsetsUrl: string = await this.storage.ref(`rooms/${meetingId}/offsets.txt`).getDownloadURL();
            const offsetsResponse = await axios.get(offsetsUrl);
            if (offsetsResponse.status === 200)
                offsets = offsetsResponse.data;
        }
        catch { console.log('no offsets file found'); }

        const allMediaSources = (await this.storage.ref(`rooms/${meetingId}`).listAll()).items;

        const filteredAudiosRef = allMediaSources.filter(item =>
            !!Rx.MediaTypes.value.some(suffix => item.name.endsWith(suffix)) &&
            (item.name.startsWith('oneman_') || item.name.startsWith('ready.')))
            .sort((a, b) => a.name > b.name ? -1 : 1);

        const filteredVideosRef = allMediaSources.filter(item => ["mkv", "mp4"].includes(item.name.slice(-3)));

        const audioSources: MediaSource[] = [];
        const videoSources: MediaSource[] = [];
        for (const audioRef of filteredAudiosRef) {
            const url = await audioRef.getDownloadURL();

            if (audioRef.name.startsWith('ready')) {
                audioSources.push({ name: "Default", src: url });
                continue;
            }

            const speakerIdEnd = audioRef.name.lastIndexOf('.');
            const speakerId = audioRef.name.slice(7, speakerIdEnd);
            const speakerDoc = await this.db.collection('users').doc(speakerId).get();
            const spakerData = speakerDoc.data();

            const speakerName = !spakerData ? 'Unknown' : spakerData.username;
            audioSources.push({ name: speakerName, src: url, offset: offsets[speakerId] || 0 });
        }
        for (const videoRef of filteredVideosRef) {
            const url = await videoRef.getDownloadURL();
            if (videoRef.name.startsWith('orig_raw')) {
                videoSources.push({ name: "Default", src: url });
                continue;
            }
            videoSources.push({ name: videoRef.name, src: url });
        }
        return { audioSources, videoSources };
    };

    public uploadAudioFile = async (file: any, audioLength: number) => {
        const newRoomId = this.ID();
        const fileRef = this.storage.ref(`rooms/${newRoomId}/raw.${file.name.slice(file.name.length - 3, file.name.length)}`);

        const uploadTask = fileRef.put(file);

        uploadTask.on('state_changed', snapshot => Rx.UploadProgress.next(snapshot.bytesTransferred / snapshot.totalBytes));

        await uploadTask;
        console.log(`Uploaded audio blob with room id ${newRoomId}`);
        Rx.UploadProgress.next(-1);

        const newRecordings = [...Rx.Recordings.value, new Recording(newRoomId, await fileRef.getDownloadURL() as string, audioLength)];
        Rx.Recordings.next(newRecordings);
    };

    public removeMeetingFromStorage = async (meetingId: string) => {
        const folderRef = firebase.storage().ref(`rooms/${meetingId}`);
        const filesInFolder = (await folderRef.listAll()).items;
        for (const fileRef of filesInFolder)
            await fileRef.delete();
        //await folderRef.delete();
    };

    public uploadUnsupervised = async (rangesInfo: RangesInfo, autoPay: boolean = false) => {
        const meetingId = rangesInfo.meetingId;
        const userId = Rx.ConnectedUserId.value;
        if (!meetingId || !userId)
            alert("לא נמצא משתמש או פגישה להעלות אליהם");

        await this.db.collection(this.roomsCollection).doc(meetingId).set(
            {
                owner_id: userId,
                group_id: rangesInfo.groupId,
                name: rangesInfo.meetingName,
                creation_time: rangesInfo.meetingDate,
                status: 1,
                algorithm: "unsupervised",
                meetingLength: rangesInfo.meetingLengthInSec,
                wasPaid: autoPay
            }
        );
        console.log(`Uploaded new meeting metadata! ${meetingId} in group ${rangesInfo.groupId}`);

        await this.db.collection(this.jobsCollection).doc(meetingId).set(
            {
                ownerId: userId,
                roomId: meetingId,
                operation: "manual_upload",
                algorithm: "unsupervised"
            }
        );
        console.log('Uploaded unsupervised algorithm manual_upload job!');
    };

    public uploadSupervised = async (rangesInfo: RangesInfo) => {
        const meetingId = rangesInfo.meetingId;
        const userId = Rx.ConnectedUserId.value;
        if (!meetingId || !userId)
            alert("לא נמצא משתמש או פגישה להעלות אליהם");

        if(!!rangesInfo.ranges) {
            const blob = new Blob([JSON.stringify(rangesInfo.ranges)], { type: "application/json" });
            const fileRef = this.storage.ref(`rooms/${meetingId}/slices.txt`);
            await fileRef.put(blob);
            console.log('Uploaded a blob!');
        }

        await this.db.collection(this.roomsCollection).doc(meetingId).set(
            {
                owner_id: userId,
                group_id: rangesInfo.groupId,
                additionalMemebers: rangesInfo.participants,
                name: rangesInfo.meetingName,
                creation_time: rangesInfo.meetingDate,
                status: !!rangesInfo.ranges ? 2.5 : 2,
                algorithm: "supervised",
                meetingLength: rangesInfo.meetingLengthInSec,
                wasPaid: true
            }
        );
        console.log(`Uploaded new meeting metadata! ${meetingId} in group ${rangesInfo.groupId}`);

        if(!!rangesInfo.ranges) {
            await this.db.collection(this.jobsCollection).doc(meetingId).set(
                {
                    ownerId: userId,
                    roomId: meetingId,
                    operation: "manual_upload",
                    algorithm: "supervised"
                }
            );
            console.log('Uploaded supervised algorithm manual_upload job!');
        }
    };

    public setUserNameAndMail = async (name: string, mail: string) => {
        await this.updateDocument('users', Rx.ConnectedUserId.value,{
            username: name,
            mail: mail
        });
        Rx.ConnectedUserName.next(name);
        Rx.ConnectedUserMail.next(mail);
    };

    public getUserNameAndMail = async (userId: string, phoneNumber: string) => {
        let doc = await this.db.collection('users').doc(userId).get();

        let data = doc.data();
        if (!data) {
            await this.db.collection('users').doc(userId).set({
                phonenumber: phoneNumber,
                username: "",
                mail: "",
                budget: 0
            });
            Rx.ConnectedUserName.next("");
            Rx.ConnectedUserMail.next("");
        }
        else {
            Rx.ConnectedUserName.next(data.username || "");
            Rx.ConnectedUserMail.next(data.mail || "");
        }
    };

    public isAdmin = async (userId: string) => {
        let doc = await this.db.collection('users').doc(userId).get();
        let data = doc.data();

        if (!data)
            return false;

        return data.su === true;
    };

    public isAdminOrTranscriber = async (userId: string) => {
        let doc = await this.db.collection('users').doc(userId).get();
        let data = doc.data();

        if (!data)
            return false;

        return data.su === true || data.transcriber === true;
    };

    public getSubtitlesUrl = async (roomId: string) => {
        const subtitlesRef = this.storage.ref(`rooms/${roomId}/subtitles.srt`);
        return await subtitlesRef.getDownloadURL();
    }

    public setTransactionId = async (roomIds: string[], transactionId: string) => {
        for (const id of roomIds) {
            await this.updateDocument(this.roomsCollection, id, { transaction_id: transactionId });
            console.log("Updated transaction id for room " + id);
        }
    };

    public getRecieptData = async (someRoomId: string): Promise<RecieptData | null> => {
        const roomDoc = await this.db.collection(this.roomsCollection).doc(someRoomId).get();
        const roomData = roomDoc.data();
        if (!roomData) {
            alert('לא נמצאה פגישה');
            return null;
        }

        const groupId = roomData.group_id;
        const transactionId = roomData.transaction_id;

        if (!groupId || !transactionId) {
            alert('המידע המבוקש לא היה במסד הנתונים');
            return null;
        }

        const ownerId = typeof(roomData.owner_id) == typeof("a") ? roomData.owner_id : roomData.owner_id.id;

        const userDoc = await this.db.collection('users').doc(ownerId).get();
        const userData = userDoc.data();
        if (!userData) {
            alert('שגיאה בקבלת פרטי המשתמש');
            return null;
        }

        const groupRoomsQuerySnapshot = await this.db.collection(this.roomsCollection).where('group_id', '==', groupId).get();

        if (!groupRoomsQuerySnapshot || groupRoomsQuerySnapshot.empty) {
            alert("שגיאה בקבלת מידע");
            return null;
        }

        let numberInGroup = 0;
        let totalLength = 0;
        for (let groupRoomdoc of groupRoomsQuerySnapshot.docs) {
            const groupRoomData = groupRoomdoc.data();

            if (!groupRoomData || !groupRoomData.meetingLength) {
                alert("שגיאה בשליפת מידע רלוונטי מפגישות בבאצ'");
                return null;
            }

            numberInGroup++;
            totalLength += groupRoomData.meetingLength;
        }

        totalLength = Math.floor(100 * totalLength / 60) / 100;

        return {
            transactionId: roomData.transaction_id,
            costumerName: userData.username,
            costumerMail: userData.mail,
            description: `תמלול ${numberInGroup} הקלטות באורך כולל של ${totalLength} דקות`
        };
    };

    public updateFoldersPath = async (meeting: MeetingPreview) => {
        await this.updateDocument(this.roomsCollection, meeting.id, { folder_path: meeting.folderPath });
    };

    public getUserAssignOptions = async () => {
        const transcribers = await this.db.collection('users').where('transcriber','==',true).get();
        const superUsers = await this.db.collection('users').where('su','==',true).get();

        const assignableUsers = _.concat(transcribers.docs, superUsers.docs)
        const idToName: {[id: string]: string} = {};

        if(!!assignableUsers && !_.isEmpty(assignableUsers)){
            assignableUsers.forEach(userDoc => {
                const userData = userDoc.data();
                let username = !!userData ? userData.username || userData.phonenumber : 'לא מזוהה';
                idToName[userDoc.id] = username;
            });
        }

        Rx.TranscriberIdToName.next(idToName);
    };

    public getAllUsers = async () => {
        const users = await this.db.collection('users').get();
        let usersArray: any[] = [];
        users.forEach(user =>
            usersArray.push({
                id: user.id,
                ...user.data()
            })
        )
        Rx.AllUsers.next(usersArray);
    };

    public setUserAsTranscriber = async (userId: string) => {
        await this.updateDocument('users', userId,{ transcriber: true });
        await this.getUserAssignOptions();
        await this.getAllUsers();
    };

    public assign = async (meetingsToAssign: string[], userId: string, ) => {
        const connectedUser = Rx.ConnectedUserId.value;
        for (const meetingId of meetingsToAssign) {
            const roomDoc = await this.db.collection(this.roomsCollection).doc(meetingId).get();
            const room = await roomDoc.data();
            const currentAssignee = room?.assigned_id || null;
            await this.updateDocument(this.roomsCollection, meetingId, { assigned_id: userId });
            //TODO REMOVE WHEN AUDIT IS GOOD:
            await this.db.collection(this.roomsCollection).doc(meetingId).collection("changelog").add({
                field: 'assigned_id',
                updatedBy: connectedUser,
                oldValue: currentAssignee,
                newValue: userId || null
            });
            await this.sendNotification(meetingId, 'assigned', false, userId);
        }
    };

    public getMediaTypes = async () => {
        const typesUrl: string = await this.storage.ref(`editor/supported_ext.txt`).getDownloadURL();

        const response = await axios.get(typesUrl);
        if (response.status !== 200) {
            alert('שגיאה בשליפת אופציות סוגי השמע');
            return;
        }

        Rx.MediaTypes.next((response.data as string).split('\n').map(_ => _.trim()).filter(_ => !!_));
    };

    public deleteMeetings = (meetingIds: string[]) => {
        Rx.Meetings.next(Rx.Meetings.value.filter(m => !meetingIds.includes(m.id)));
        meetingIds.forEach(id => {
            this.updateDocument(this.roomsCollection, id, { status: 5 });
            // this.removeMeetingFromStorage(id);
        });
    };

    public generateID = () => '_' + Math.random().toString(36).substr(2, 9);

    public setReady = async (meetingId: string, transcriberIdAssigned: string, readyDate: any[] = [], options?: {}) => {
        await this.updateDocument(this.roomsCollection, meetingId, { ready_date: readyDate ?? null, ...options});

        const meeting = Rx.ChosenMeeting.value;
        if (!meeting) {
            console.log("notmeeting")
            return;
        }
        const storageRef = await firebase.storage();
        await storageRef.setMaxUploadRetryTime(0);
        const validatedWords = await this.validateSubtitlesRanges(meeting.words);
        const blob = new Blob([JSON.stringify(validatedWords)], { type: "application/json" });

        if (!!readyDate) {
            await this.sendNotification(meetingId, 'ready', true);
            const newRevisionRef = storageRef.ref(`rooms/${meetingId}/validated/ready_${Date.now()}.json`)
            await newRevisionRef.put(blob);
        } else {
            await this.sendNotification(meetingId, 'not_ready', false, transcriberIdAssigned);
            const newRevisionRef = storageRef.ref(`rooms/${meetingId}/validated/not_ready_${Date.now()}.json`)
            await newRevisionRef.put(blob);
        }
    };

    public setDelivered = async (meetingId: string, delivered: any[] = [], transcriberIdAssigned: string, options?: {}) => {
        const meeting = Rx.ChosenMeeting.value;
        if (!meeting) {
            console.log("notmeeting")
            return;
        }

        await this.updateDocument(this.roomsCollection, meetingId, { delivered_to_client: delivered, ...options });

        if (delivered)
            await this.sendNotification(meetingId, 'sent', true, transcriberIdAssigned);

        const storageRef = await firebase.storage();
        await storageRef.setMaxUploadRetryTime(0);
        const validatedWords = await this.validateSubtitlesRanges(meeting.words);
        const blob = new Blob([JSON.stringify(validatedWords)], { type: "application/json" });

        await this.sendNotification(meetingId, 'ready', true);
        const newRevisionRef = storageRef.ref(`rooms/${meetingId}/validated/sent_${delivered}_${Date.now()}.json`)
        await newRevisionRef.put(blob);
    };

    public moveBatchToMyMeetings = async (batch: RecordingBatch) => {
        for(const id of batch.recordingIds)
            await this.updateDocument(this.roomsCollection, id, { status: 3 });
    };

    public sendNotification = async (roomId: string, event: 'payed' | 'assigned' | 'ready' | 'not_ready' | 'sent', sendToAdmin: boolean, transcriberId?: string) => {
        const docRef = this.db.collection('emails').doc();

        const object = !!transcriberId ? {
                room_id: roomId,
                event,
                su: sendToAdmin,
                transcriber: transcriberId
            } :
            {
                room_id: roomId,
                event,
                su: sendToAdmin
            };

        await docRef.set(object);
        console.log('Saved to emails collection ' + docRef.id);
    };

    public writeLog = async (newWords: string[], oldWords: string[], recordingTime: string) => {
        const docRef = this.db.collection('wordDuplicationLogs').doc();
        const meetingName = (Rx.ChosenMeeting.value as Meeting).metadata.name;
        const userName = Rx.ConnectedUserName.value;

        const log = {
            created: Date(),
            userName,
            meetingName,
            recordingTime,
            newWords,
            oldWords,
            version
        };

        await docRef.set(log);
    };

    public writeSubtitlesLog = async (data: any) => {
        if (data.errors.length == 0) return;
        const docRef = this.db.collection('subtitlesLog').doc();
        const meetingName = (Rx.ChosenMeeting.value as Meeting).metadata.name;
        const userName = Rx.ConnectedUserName.value;

        const log = {
            created: Date(),
            userName,
            meetingName,
            errors: data.errors,
            version
        };

        await docRef.set(log);
    };

    private validateSubtitlesRanges = async (words: Word[]) => {
        const updateMeetingWords: Word[] = [];
        let rangeIndex: number = 0;
        let errors = words[0].range_ix === 0 ? [] : [`first word range_ix == ${words[0].range_ix}`];

        for (let i = 0; i < words.length; i++) {
            if (i !== 0 && words[i].range_ix > words[i-1].range_ix) {
                if ((words[i].range_ix - words[i-1].range_ix) > 1) {
                    errors.push(`words[${i-1}].range_ix == ${words[i-1].range_ix} | words[${i}].range_ix == ${words[i].range_ix}`)
                }
                rangeIndex++;
            }
            updateMeetingWords.push({
                ...words[i],
                range_ix: rangeIndex
            })
        }

        await this.writeSubtitlesLog({errors})
        return updateMeetingWords;
    }
}

export default Firebaser.getInstance();
