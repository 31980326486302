import * as React from "react";
import { FC } from 'react';
import { SingleInputDialog } from "../common/SingleInputDialog";
import { Rx } from "../../services/Rx";
import firebase from "firebase/app";
import Firebaser from "../../services/Firebaser";
import { useSubscription } from "../common/useSubscription";
import { ContactDetailInputDialog } from "../common/ContactDetailInputDialog";

interface AuthenticationProps {
	shouldStart?: boolean;
}

export const Autentication: FC<AuthenticationProps> = ({ shouldStart = true }) => {
	const phoneEntered = useSubscription(Rx.enteredPhoneNumber);
	const authenticatedUser = useSubscription(Rx.ConnectedUserId);
	const authenticatedUserName = useSubscription(Rx.ConnectedUserName);
	const authenticatedUserMail = useSubscription(Rx.ConnectedUserMail);

	const [isMail, setIsMail] = React.useState(false);

	const [phoneEnterResult, setPhoneEnterResult] = React.useState<firebase.auth.ConfirmationResult>();
	const [emailEnteredResult, setEmailEnteredResult] = React.useState(false);
	const [showError, setShowError] = React.useState(false);

	React.useEffect(() => {
		const isEmailLink = Firebaser.auth.isSignInWithEmailLink(window.location.href);
		const email = window.localStorage.getItem('emailForSignIn') || "";
		isEmailLink && Firebaser.auth.signInWithEmailLink(email, window.location.href).then((res) => {
			console.log("Res", res);
		})
	}, [])

	const onEmailEnter = async (email: string) => {
		try {
			const actionCodeSettings = {
				url: window.location.href,
				handleCodeInApp: true,
			};
			await Firebaser.auth.sendSignInLinkToEmail(email, actionCodeSettings);
			setEmailEnteredResult(true);
			setShowError(false);
			window.localStorage.setItem('emailForSignIn', email);
		}
		catch (e) {
			console.log(e);
			setShowError(true);
		}
	};

	const onPhoneEnter = async (rawPhoneNumber: string) => {
		const phoneNumber = rawPhoneNumber.startsWith('0') ? '+972' + rawPhoneNumber.slice(1) : rawPhoneNumber;

		try {
			const verifier = await Firebaser.auth.signInWithPhoneNumber(phoneNumber, Firebaser.verifier);
			setPhoneEnterResult(verifier);
			Rx.enteredPhoneNumber.next(true);
			setShowError(false);
		}
		catch (e) {
			console.log(e);
			setShowError(true);
		}
	};

	const onCodeEnter = async (code: string) => {
		if (!phoneEnterResult) {
			setShowError(true);
			return;
		}

		let userCredentials: firebase.auth.UserCredential;
		try {
			userCredentials = await phoneEnterResult.confirm(code);
			console.log(userCredentials);
		}
		catch (e) {
			console.log(e);
			setShowError(true);
			return;
		}

		setShowError(false);
	};

	if (!shouldStart)
		return <></>;

	const agreementComponent = <span style={{ marginTop: '0.5em' }}>
		*בהתחברות אני מאשר את ה
		<a href='https://www.sumit-ai.com/takanon.html' target="_blank" rel="noopener noreferrer">תקנון</a>
	</span>;

	return (
		<>
			<SingleInputDialog onInput={isMail ? onEmailEnter : onPhoneEnter}
							   ltrInput={true}
							   open={!phoneEntered && !authenticatedUser}
							   placeholder={`מה ${isMail ? "כתובת המייל" : "מספר הטלפון"} שלך?`}
							   buttonText={`שלחו לי קוד ב-${isMail ? "mail" : "sms"}`}
							   error={showError ? "מספר טלפון לא תקין, נסה שנית" : ""}
							   isMail={isMail}
							   changeAuthMethod={setIsMail}
							   emailSent={emailEnteredResult}/>
			<SingleInputDialog onInput={onCodeEnter}
							   ltrInput={true}
							   open={phoneEntered && !authenticatedUser}
							   placeholder="מה הקוד שקיבלת?" buttonText="התחברות"
							   error={showError ? "מסרון שגוי, נסה שנית" : ""}
							   extraContent={agreementComponent}
							   isMail={isMail}
							   changeAuthMethod={() => {
				Rx.enteredPhoneNumber.next(false)
				setIsMail(true)
			}}/>
			<ContactDetailInputDialog
				onInput={Firebaser.setUserNameAndMail}
				open={!!authenticatedUser && (!authenticatedUserName || !authenticatedUserMail)}
				name={authenticatedUserName}
				mail={authenticatedUserMail}/>
		</>
	);
}
