const project_name = "sumit-dev";
const project_id = "sumit-dev-0";
const project_number = "360745002639";
const messaging_sender_id = "360745002639";
const web_api_key = "AIzaSyB9fmJ3aM8i3V_beh_nnX3-WxWs-M15IEg";
const app_id = "1:360745002639:web:cea091a3a2ac75bc87f2fa";

module.exports = {
    environment: "development",
    isDev: true,
    firebase: {
        apiKey: web_api_key,
        authDomain: `${project_id}.firebaseapp.com`,
        databaseURL: `https://${project_id}.firebaseio.com`,
        projectId: project_id,
        storageBucket: `gs://${project_id}.appspot.com`,
        messagingSenderId: messaging_sender_id,
        appId: app_id
    },
    cloudFunctions: {
        url: "https://us-central1-sumit-dev-0.cloudfunctions.net/",
        json2srt: "json2srt"
    }
}
