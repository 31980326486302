import _ from "lodash";
import * as React from "react";
import { FC, useState, useEffect, useRef } from 'react';
import { Rx } from "../../services/Rx";
import { useSubscription } from "../common/useSubscription";
import { Meeting } from "../../model/meeting";
import { Word } from "../../model/word";
import { TextField } from "@material-ui/core";
import { AccountCircleOutlined } from "@material-ui/icons";
import classNames from "classnames";
import { SpeakersDialog } from "./SpeakersDialog";
import "./TextRange.css";
import "./InteractiveWord.css";

//@ts-ignore
import TimePicker from 'react-time-picker'
import EditorService from "../../services/EditorService";
import WordsManager from "../../services/WordsManager";
import SoundManager from "../../services/SoundManager";
import IndexedListColorManager from "../../services/IndexedListColorManager";
import TimeManager from "../../services/TImeManager";


interface Props {
    rangeIndex: number;
    firstWordIndex: number;
    rangeSize: number;
    rangesCount: number;
    mergeRanges: (mergeWithNextRange: boolean) => void;
    breakRange: (wordIndex: number, wordCharIndex: number, newRange: boolean) => void;
    updateWordTime: (wordIndex: number, time: string, position: string) => void;
    isSubtitles: boolean; // check if needed
    isPassed: boolean;
    overlapping: boolean;
}

export const TextRange:
    FC<Props> = ({
        rangeIndex,
        firstWordIndex,
        rangeSize,
        rangesCount,
        mergeRanges,
        breakRange,
        updateWordTime,
        isSubtitles,
        isPassed,
        overlapping
    }) => {

    const meeting = useSubscription(Rx.ChosenMeeting);
    const ltr = useSubscription(Rx.LTR);

    const [isChanged, setIsChanged] = useState(false);
    const [rangeWords, setRangeWords] = useState<Word[]>([]);
    const [plainWords, setPlainWords] = useState("");
    const [rangeTimes, setRangeTimesState] = useState<{[key: string]: string}>({ start: "", end: "" });
    const [speakersDialogOpen, setSpeakersDialogOpen] = useState(false);
    const textInputRef = useRef();

    useEffect(() => {
        if (!meeting) return;

        setRangeAndPlainWords(meeting.words);
    }, []);

    useEffect(() => {
        if (!meeting) return;
        // TODO Consider calling only setRangeWords
        const isFocused = document.activeElement === textInputRef.current;
        if (!isFocused || !isChanged) {
            setRangeAndPlainWords(meeting.words);
            setRangeTimes()
        }
    }, [rangeSize, meeting, isSubtitles]);

    if (!meeting) return null;

    const getRangeWords = (meetingWords: Word[] = meeting.words, plainRangeSize = rangeSize) => {
        return meetingWords.slice(firstWordIndex, firstWordIndex + plainRangeSize);
    };

    const setRangeAndPlainWords = (meetingWords: Word[]) => {
        const rangeSlice = getRangeWords(meetingWords);
        const rangeString = rangeSlice.map(word => word.text).join(" ");
        setPlainWords(rangeString);
        setRangeWords(rangeSlice);
    };

    const setRangeTimes = () => {
        if (!isSubtitles || firstWordIndex >= meeting.words.length) return;
        const start = TimeManager.getTimeStringFromSecs(meeting.words[firstWordIndex].start, false, true)
        const end = TimeManager.getTimeStringFromSecs(meeting.words[firstWordIndex + rangeSize-1].end, false, true)
        setRangeTimesState({
            start,
            end
        });
    };

    const updateRxMeetingWords = (newMeetingWords: Word[]) => {
        (Rx.ChosenMeeting.value as Meeting).words = newMeetingWords
        Rx.ChangesToSave.next(true);
    };

    const updateRxMeetingNext = async (trimWordText: boolean = true) => {
        const meetingCopy = { ...Rx.ChosenMeeting.value } as Meeting;
        if (trimWordText) {
            meetingCopy.words =
                _.map(meetingCopy.words, word => ({
                    ...word,
                    text: word.text.trim()
                }));
        }

        await Rx.ChosenMeeting.next(meetingCopy);
        Rx.ChangesToSave.next(true);
    };

    if (_.isEmpty(rangeWords)) return null;

    // --- Range Manipulators ---

    const handleBreakRange = async (e: React.KeyboardEvent, newRange: boolean) => {
        const { selectionStart } = e.target as HTMLTextAreaElement
        await handleBlur();
        // await updateRxMeetingNext();

        if (!meeting.words) return;

        const { rangeWordIndex, wordCharIndex} = getCurrentWordIndex(selectionStart);
        breakRange(rangeWordIndex, wordCharIndex, newRange)
    }

    const handleMergeRange = (mergeWithNextRange: boolean = false) => {
        mergeRanges(mergeWithNextRange)
    }

    const getCurrentWordIndex = (cursorPosition: number) => {
        const trimmedWordCount = plainWords.trim().split(" ").filter(word=>word).length;
        const rangeWordsMeeting = getRangeWords(meeting.words, trimmedWordCount);
        const cursorDiff = getCursorFixedPositionAfterTrim(cursorPosition);
        let rangeWordIndex = 0;
        let wordCharIndex: number = cursorPosition + cursorDiff;

        while (wordCharIndex > 0 && wordCharIndex > rangeWordsMeeting[rangeWordIndex].text.length) {
            const wordToCheck = rangeWordsMeeting[rangeWordIndex].text.length;
            wordCharIndex = wordCharIndex - (wordToCheck + 1) // +1 for space
            rangeWordIndex++;
        }

        return { rangeWordIndex, wordCharIndex };
    };

    const getCursorFixedPositionAfterTrim = (cursorPosition: number) => {
        const rangeFirstHalfTrimmedLength = plainWords
            .slice(0, cursorPosition)
            .trim()
            .split(" ")
            .filter(word=>word)
            .join(" ")
            .length;
        const cursorDiff = (rangeFirstHalfTrimmedLength +1) - cursorPosition;
        return cursorDiff;
    };

    const focusAndSetCursor = (rangeIndexToFocus: number, cursorPosition: number) => {
        const rangeToFocus = document.getElementById(`range-${rangeIndexToFocus}`);
        if (rangeToFocus) {
            //@ts-ignore
            const cursorPositionToSet = cursorPosition > -1 ? cursorPosition : rangeToFocus.value.length +1; // +1 to set cursor after the space
            rangeToFocus.focus();
            //@ts-ignore
            setTimeout(() => rangeToFocus.setSelectionRange(cursorPositionToSet, cursorPositionToSet), 0)
        } else {
            // Retry - for creating new range at the end
            setTimeout(() => focusAndSetCursor(rangeIndexToFocus, cursorPosition), 10);
        }
    };

    const tryChangeSpeaker = (startIndex: number, endIndex: number) => {
        const indices = [];
        for (let i = startIndex; i <= endIndex; i++)
            indices.push(i + firstWordIndex);
        Rx.SelectedWordInd.next(indices);

        if (Rx.SelectedWordInd.value.length > 0)
            setSpeakersDialogOpen(true);
    }

    const getSpeakerColor = () => {
        if (_.isEmpty(rangeWords) || !meeting)
            return "gray";
        const speakerIndex = !rangeWords[0].speaker ? -1 :
            Array.from(meeting.metadata.participants).findIndex(p => p.trim() === getSpeakerName().trim());
        return IndexedListColorManager.getColorByIndex(speakerIndex);
    }

    const getSpeakerName = () => {
        if (_.isEmpty(rangeWords) || !meeting)
            return "דובר לא ידוע";
        return WordsManager.getSpeakerName(rangeWords[0], meeting.metadata);
    };

    // --- Event Handlers ---

    const handleRangeTimeUpdate = (position: string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        setRangeTimesState({
            ...rangeTimes,
            [position]: value
        })
        setIsChanged(true);
        Rx.ChangesToSave.next(true);
    }

    const handleRangeTimeBlur = (position: string) => {
        const wordToUpdate = (position === "start" ? _.first(rangeWords) : _.last(rangeWords)) as Word;
        const wordIndex = meeting.words.indexOf(wordToUpdate);
        updateWordTime(wordIndex, rangeTimes[position], position);
        setIsChanged(false);
    }

    const handleRangeKeyDown = (e: React.KeyboardEvent, position: string) => {
        if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey])) {
            handleRangeTimeBlur(position);
            WordsManager.saveChangedMeeting();
        }
    }

    const handleKeyboardShortcut = (e: React.KeyboardEvent, code: string[], conditions: boolean[] = [], preventDefault = true) => {
        if (code.includes(e.nativeEvent.code) && (conditions ? _.every(conditions) : true)) {
            if (preventDefault) {
                e.stopPropagation();
                e.preventDefault();
            }
            return true;
        }
        return false;
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newInputString = e.target.value;
        const oldInputString = plainWords;

        setIsChanged(true);
        setPlainWords(newInputString);

        const newMeetingWords = EditorService.getMeetingWordsFromString(
            meeting.words,
            newInputString,
            oldInputString,
            firstWordIndex,
            rangeWords[0].speaker,
            rangesCount
        )
        updateRxMeetingWords(newMeetingWords)
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
        const textLength = _.get(e, "target.textLength");

        if (handleKeyboardShortcut(e, ["Enter", "NumpadEnter"])) {
            if (!isSubtitles) return;

            if (selectionStart === 0) {
                handleMergeRange(true)
                focusAndSetCursor(rangeIndex, 0);
                return
            }

            handleBreakRange(e, e.ctrlKey)
            focusAndSetCursor(rangeIndex+1, 0)
            return;
        }

        if (handleKeyboardShortcut(e, ["Backspace"], [selectionStart === 0])) {
            if (!isSubtitles) return;

            handleMergeRange()
            focusAndSetCursor(rangeIndex-1, -textLength)
            return;
        }

        if (handleKeyboardShortcut(e, ["Delete"], [selectionStart === textLength])) {
            if (!isSubtitles) return;

            handleMergeRange(true)
            focusAndSetCursor(rangeIndex, textLength)
            return;
        }

        if (handleKeyboardShortcut(e, ["Tab"])) {
            const rangeIndexToFocus = e.nativeEvent.shiftKey ? rangeIndex -1 : rangeIndex +1;
            focusAndSetCursor(rangeIndexToFocus, 0);
        }

        if (handleKeyboardShortcut(e, ["ArrowLeft", "ArrowRight"], [], false)) {
            const goBack = selectionStart === 0 && ((ltr && e.nativeEvent.code === 'ArrowLeft') || !ltr && e.nativeEvent.code === "ArrowRight");
            const goForward = selectionStart === textLength && ((ltr && e.nativeEvent.code === 'ArrowRight') || !ltr && e.nativeEvent.code === "ArrowLeft");

            if (goBack) {
                const rangeIndexToFocus = rangeIndex -1;
                const rangeToFocus = document.getElementById(`range-${rangeIndexToFocus}`);
                const rangeToFocusLength = _.get(rangeToFocus, "value.length");
                focusAndSetCursor(rangeIndexToFocus, rangeToFocusLength);
            }
            if (goForward) {
                const rangeIndexToFocus = rangeIndex +1;
                focusAndSetCursor(rangeIndexToFocus, 0);
            }

            if (goBack || goForward) {
                e.preventDefault();
                e.stopPropagation();
            }
        }

        if (handleKeyboardShortcut(e, ["KeyS"], [e.ctrlKey])) {
            handleBlur();
            WordsManager.saveChangedMeeting();
        }

        if (handleKeyboardShortcut(e, ["KeyD"], [e.ctrlKey])) {
            if (isSubtitles) return;

            handleBlur();

            const { startWordIndex, endWordIndex } = EditorService.getSelectedWordsIndex(plainWords, selectionStart, selectionEnd);

            tryChangeSpeaker(startWordIndex, endWordIndex);
        }
        // -- Editor Shortcuts --
    };

    const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
        //@ts-ignore
        let cursorOffset = e.target.selectionStart;

        const editedWords = plainWords.split(' ');
        const lengths = editedWords.map(word => word.length);

        let clickedWord = -1;
        while (cursorOffset > 0) {
            clickedWord++;
            cursorOffset = cursorOffset - lengths[clickedWord] - 1;
        }
        if (cursorOffset === 0)
            clickedWord++;

        !!rangeWords && !!rangeWords[clickedWord] && SoundManager.setOffset(rangeWords[clickedWord].start);
    };

    const handleBlur = async () => {
        setRangeAndPlainWords(meeting.words)

        if (!isChanged) return;

        await updateRxMeetingNext()

        setIsChanged(false);
    }

    return (
        <>
            <div className={classNames("textRange", {
                ltr,
                passed: isPassed,
                subtitles: isSubtitles,
                overlapping: overlapping
            })}>
                <div className="rangeTitle">
                    {isSubtitles ? (
                        <div className="subtitleRange">
                            <div className="timestamps">
                                <TextField
                                    className="timepicker"
                                    value={rangeTimes.start}
                                    onChange={(event) => handleRangeTimeUpdate("start", event)}
                                    onBlur={() => handleRangeTimeBlur("start")}
                                    onKeyDown={(event) => handleRangeKeyDown(event,"start")}
                                    inputProps={{
                                        step: 0.1,
                                    }}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    type="time"
                                />
                                <TextField
                                    className="timepicker"
                                    value={rangeTimes.end}
                                    onChange={(event) => handleRangeTimeUpdate("end", event)}
                                    onBlur={() => handleRangeTimeBlur("end")}
                                    onKeyDown={(event) => handleRangeKeyDown(event,"end")}
                                    inputProps={{
                                        step: 0.1,
                                    }}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    type="time"
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="rangeSpeaker" style={{ color: getSpeakerColor() }}>
                                <AccountCircleOutlined />
                                &nbsp;
                                <span><b>{getSpeakerName()}</b></span>
                            </div>
                            <span className="timeInMeeting">{TimeManager.getTimeStringFromSecs(rangeWords[0].start)}</span>
                        </>
                    )}
                </div>

                <TextField
                    id={`range-${rangeIndex}`}
                    className="textRangeField"
                    value={plainWords}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    onClick={handleClick}
                    onBlur={handleBlur}
                    inputProps={{style: { textAlign: (ltr ? 'left' : 'right'), direction: (ltr ? 'ltr' : 'rtl')}, ref: textInputRef}}
                    variant='outlined'
                    multiline
                    // onContextMenu={handleRightClick} // Disabling change speaker on rightClick
                />
            </div>

            <SpeakersDialog
                open={speakersDialogOpen}
                onClose={() => setSpeakersDialogOpen(false)}
                speakerOptions={Array.from(meeting.metadata.participants)}
            />
        </>
    );
}
